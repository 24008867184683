import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { actions } from "../actions"

export const setFetchingCoins = (
  payload: types.setFetchingCoinsAction["payload"]
): types.CoinsActionTypes => ({
  type: types.setFetchingCoins,
  payload,
})

export const storeCoins = (
  payload: types.storeCoinsAction["payload"]
): types.CoinsActionTypes => ({
  type: types.storeCoins,
  payload,
})

export const spend = (
  payload: types.spendAction["payload"]
): types.CoinsActionTypes => ({
  type: types.spend,
  payload,
})

export const addCoins = (
  payload: types.addCoinsAction["payload"]
): types.CoinsActionTypes => ({
  type: types.addCoins,
  payload,
})

export const $spend =
  (amount: number): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (!auth.user.id) return false

    if (amount !== 0) {
      dispatcher(spend({ amount }))
      dispatcher(
        actions.notifications.$createCoins({
          amount: -amount,
          type: "coin",
        })
      )
      di.CoinsRepository.spend({
        userId: auth.user.id,
        amount,
      })
    }
  }

export const $addCoins =
  (amount: number): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (!auth.user.id) return false

    if (amount !== 0) {
      dispatcher(addCoins({ amount }))
      dispatcher(
        actions.notifications.$createCoins({
          amount: amount,
          type: "coin",
        })
      )
      di.CoinsRepository.add({
        userId: auth.user.id,
        amount,
      })
    }
  }

export const $fetch =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setFetchingCoins({ fetching: true }))

    const response = auth.user?.id
      ? await di.CoinsRepository.getTotalAmount({
          userId: auth.user.id,
        })
      : { amount: 0 }

    dispatcher(
      storeCoins({
        amount: response.amount,
      })
    )

    dispatcher(setFetchingCoins({ fetching: false }))
  }
