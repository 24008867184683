import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../entities/LeaderboardEntity"
import { UserEntity } from "../entities/UserEntity"
import { ILeaderboardRepository } from "../interfaces/ILeaderboardRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseLeaderboardRepository
  extends FirebaseUtils
  implements ILeaderboardRepository
{
  private collection = "leaderboard"

  constructor(private firebase: Firebase) {
    super()
  }

  async storeUserOnLeague(user: LeaderboardEntity): Promise<LeaderboardEntity> {
    const firestore = this.firebase.database()

    await firestore.collection(this.collection).doc(user.userId).set(user)

    return user
  }

  async getMeta(): Promise<MetaLeaderboardEntity> {
    const firestore = this.firebase.database()
    const response = await firestore.collection("meta").doc("leaderboard").get()
    const meta = response.data()
    return {
      ...meta,
      end: meta.end.toDate(),
      start: meta.start.toDate(),
    } as MetaLeaderboardEntity
  }

  async getUserLeague(params: {
    userId: string
  }): Promise<LeaderboardEntity[]> {
    const firestore = this.firebase.database()

    const user = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!user.exists) return []

    const data = user.data() as LeaderboardEntity

    const response = await firestore
      .collection(this.collection)
      .where("league", "==", data.league)
      .where("bracket", "==", data.bracket)
      .get()

    return this.mapQuerySnapshot<LeaderboardEntity>(response)
  }

  async storePoints(params: {
    userId: string
    points: number
    avatar: string
    username: string
  }): Promise<LeaderboardEntity> {
    const firestore = this.firebase.database()
    const doc = firestore.collection(this.collection).doc(params.userId)
    const getDocument = await doc.get()
    const data = getDocument.data() as LeaderboardEntity

    if (!data) {
      return data
    }

    const entity = {
      ...data,
      ...params,
      points: params.points + data?.points || 0,
    }

    await doc.set(entity)

    return entity
  }
}
