import dayjs from "dayjs"
import { StatEntity } from "../entities/StatEntity"
import {
  IStatsRepository,
  IStatsRepositoryPeriod,
} from "../interfaces/IStatsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseStatsRepository
  extends FirebaseUtils
  implements IStatsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "stats"

  async findAll(params: {
    period: IStatsRepositoryPeriod
    userId: string
  }): Promise<StatEntity[]> {
    const today = dayjs()
    const endDigit =
      params.period === "week" ? 7 : params.period === "month" ? 30 : 12
    const endUnit = params.period === "year" ? "month" : "day"

    const end = dayjs().subtract(endDigit, endUnit)

    return this.getAll({
      userId: params.userId,
      interval: {
        end: today.toDate(),
        start: end.toDate(),
      },
    })
  }

  async findToday(params: { userId: string }): Promise<StatEntity[]> {
    const firestore = this.firebase.database()
    const response = await firestore
      .collection("users")
      .doc(params.userId)
      .collection(this.collection)
      .limit(1)
      .where("date", ">=", dayjs().startOf("day").toDate())
      .get()

    if (response.empty) return []

    return this.mapQuerySnapshot<StatEntity>(response.docs).map((stat) => ({
      ...stat,
      // @ts-ignore
      date: stat.date.toDate(),
    }))
  }

  private async getAll(params: {
    userId: string
    interval?: {
      start: Date
      end: Date
    }
  }): Promise<StatEntity[]> {
    const firestore = this.firebase.database()
    const collection = firestore
      .collection("users")
      .doc(params.userId)
      .collection(this.collection)

    const response = await (params.interval
      ? collection
          .where("date", ">=", params.interval.start)
          .where("date", "<=", params.interval.end)
      : collection
    ).get()

    if (response.empty) return []

    return this.mapQuerySnapshot<StatEntity>(response.docs).map((stat) => ({
      ...stat,
      // @ts-ignore
      date: stat.date.toDate() as Date,
    }))
  }

  async store(params: {
    stats: StatEntity
    userId: string
  }): Promise<StatEntity> {
    const firestore = this.firebase.database()
    const collection = firestore
      .collection("users")
      .doc(params.userId)
      .collection(this.collection)

    const id = collection.doc().id

    await collection.doc(id).set({ ...params.stats, id })

    return params.stats
  }

  async sync(params: { userId: string }): Promise<{ succeed: boolean }> {
    try {
      const stats: StatEntity[] = JSON.parse(
        window.localStorage.getItem("stats") || "[]"
      )

      if (stats.length === 0) return { succeed: true }

      for (const stat of stats) {
        await this.store({
          stats: {
            ...stat,
            date: new Date(stat.date),
          },
          userId: params.userId,
        })
      }

      window.localStorage.removeItem("stats")

      return { succeed: true }
    } catch (e) {
      return { succeed: false }
    }
  }
}
