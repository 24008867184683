import { ChestEntity } from "../../entities/ChestEntity"
import * as types from "./types"

interface ChestsState {
  fetching: boolean
  chests: ChestEntity[]
  modal: ChestEntity | null
}

const initialState: ChestsState = {
  fetching: false,
  chests: [],
  modal: null,
}

export function chestsReducer(
  state = initialState,
  action: types.ChestsActionTypes
): ChestsState {
  if (action.type === types.setFetchingChests) {
    return {
      ...state,
      fetching: action.payload.fetching,
    }
  }

  if (action.type === types.storeChests) {
    return {
      ...state,
      chests: action.payload.chests,
    }
  }

  if (action.type === types.openModalChest) {
    return {
      ...state,
      modal: state.chests[0] || null,
    }
  }

  return state
}
