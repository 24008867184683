exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-achievements-tsx": () => import("./../../../src/templates/achievements.tsx" /* webpackChunkName: "component---src-templates-achievements-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-cookies-tsx": () => import("./../../../src/templates/cookies.tsx" /* webpackChunkName: "component---src-templates-cookies-tsx" */),
  "component---src-templates-courses-picker-tsx": () => import("./../../../src/templates/courses-picker.tsx" /* webpackChunkName: "component---src-templates-courses-picker-tsx" */),
  "component---src-templates-daily-tsx": () => import("./../../../src/templates/daily.tsx" /* webpackChunkName: "component---src-templates-daily-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-leaderboard-tsx": () => import("./../../../src/templates/leaderboard.tsx" /* webpackChunkName: "component---src-templates-leaderboard-tsx" */),
  "component---src-templates-lesson-tsx": () => import("./../../../src/templates/lesson.tsx" /* webpackChunkName: "component---src-templates-lesson-tsx" */),
  "component---src-templates-marketplace-tsx": () => import("./../../../src/templates/marketplace.tsx" /* webpackChunkName: "component---src-templates-marketplace-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-print-tsx": () => import("./../../../src/templates/print.tsx" /* webpackChunkName: "component---src-templates-print-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-signin-link-validation-tsx": () => import("./../../../src/templates/signin-link-validation.tsx" /* webpackChunkName: "component---src-templates-signin-link-validation-tsx" */),
  "component---src-templates-signin-tsx": () => import("./../../../src/templates/signin.tsx" /* webpackChunkName: "component---src-templates-signin-tsx" */),
  "component---src-templates-stats-tsx": () => import("./../../../src/templates/stats.tsx" /* webpackChunkName: "component---src-templates-stats-tsx" */)
}

