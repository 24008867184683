import { ChestEntity } from "../entities/ChestEntity"
import { IChestRepository } from "../interfaces/IChestRepository"

export class InMemoryChestRepository implements IChestRepository {
  private chests: Map<ChestEntity["id"], ChestEntity> = new Map()

  async getAll(params: { userId: string }): Promise<ChestEntity[]> {
    return Array.from(this.chests.values())
  }

  async remove(params: { userId: string; chestId: string }): Promise<void> {
    this.chests.delete(params.chestId)
  }

  async create(params: { chest: ChestEntity }): Promise<ChestEntity> {
    this.chests.set(params.chest.id, params.chest)

    return params.chest
  }
}
