import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { ArticleEntity, ArticleTypes } from "../../entities/ArticleEntity"
import { Mdx } from "../Mdx/Mdx"
import { Title } from "../Article/components/Title"
import { Image } from "../Article/components/Image"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { navigate } from "gatsby"

export type WhatsNewModalProps = ArticleEntity
export const WhatsNewModal: React.FC<WhatsNewModalProps> = (props) => {
  const location = useLocation()

  const isOpen = location.href?.includes("news=open") || false

  const onClose = () => navigate(-1)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 overflow-y-auto`}
        onClose={onClose}
      >
        <div className="min-h-screen p-2 sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-75 " />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative max-w-2xl mx-auto rounded-lg shadow-lg">
              <div className="bg-white rounded-lg shadow-xs">
                <div className="pt-5 pb-6">
                  <div className="flex px-6 items-baseline justify-between">
                    <div className="font-display font-semibold text-pink-400">
                      <FormattedMessage id="news-modal/label" />
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        onClick={onClose}
                        className="inline-flex items-center justify-center p-2 text-slate-400 transition duration-150 ease-in-out rounded-md   hover:text-slate-500  :bg-slate-900    :text-slate-100 hover:bg-slate-100 focus:outline-none focus:bg-slate-100 focus:text-slate-500"
                      >
                        <svg
                          className="w-6 h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="px-2 -mt-8">
                    {props.content?.map((block, index) => {
                      if (block.type === ArticleTypes.RICH_TEXT)
                        return <Mdx key={index}>{block.content}</Mdx>
                      if (block.type === ArticleTypes.TITLE)
                        return <Title key={index} {...block} />
                      if (block.type === ArticleTypes.IMAGE)
                        return <Image key={index} {...block} />

                      return <></>
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
