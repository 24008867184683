import { ILocalStorageEmailRepository } from "../interfaces/ILocalStorageEmailRepository"

export class BrowserLocalStorageEmailRepository
  implements ILocalStorageEmailRepository
{
  private key = "auth/signin/link/email"

  async store(email: string) {
    try {
      window.localStorage.setItem(this.key, email)
    } catch (e) {}
  }

  async get() {
    try {
      return window.localStorage.getItem(this.key) || null
    } catch (e) {
      return null
    }
  }
}
