import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../entities/LeaderboardEntity"
import { ILeaderboardRepository } from "../interfaces/ILeaderboardRepository"

export class InMemoryLeaderboardRepository implements ILeaderboardRepository {
  private points: number = 0
  private meta: MetaLeaderboardEntity = {
    end: new Date(),
    start: new Date(),
    brackets: [0],
  }

  private leaderboard: LeaderboardEntity[] = []

  async getUserLeague(params: {
    userId: string
  }): Promise<LeaderboardEntity[]> {
    return this.leaderboard.slice(0)
  }

  async storePoints(params: {
    userId: string
    points: number
  }): Promise<LeaderboardEntity> {
    this.leaderboard = this.leaderboard.map((player) => {
      if (player.userId === params.userId) return { ...player, ...params }
      return player
    })

    return this.leaderboard.find(
      (player) => player.userId === params.userId
    ) as LeaderboardEntity
  }

  async getMeta(): Promise<MetaLeaderboardEntity> {
    return this.meta
  }

  async storeUserOnLeague(user: LeaderboardEntity): Promise<LeaderboardEntity> {
    this.leaderboard.push(user)
    return user
  }
}
