import * as types from "./types"
import { PlayService } from "../../services/PlayService"
import { GameEntity, MatrixEntity } from "../../entities/GameEntity"
import { StatForShareableLink } from "../../entities/StatEntity"

interface PlayState {
  games: GameEntity[]
  stats: StatForShareableLink
  time: number
  errors: number
  playing: boolean
  actual: PlayService | null
  draftMode: boolean
  paused: boolean
  win: boolean
  showErrors: boolean
  matrix: MatrixEntity | null
  selected: string | null
}

const initialState: PlayState = {
  games: [],
  stats: { level: "easy", errors: 0, clues: 0, duration: 0 },
  time: 0,
  errors: 0,
  showErrors: true,
  draftMode: false,
  playing: false,
  actual: null,
  matrix: null,
  paused: false,
  win: false,
  selected: null,
}

export function playReducer(
  state = initialState,
  action: types.PlayActionTypes
): PlayState {
  if (action.type === types.Up) {
    if (!state.actual) return state

    state.actual.up()

    return {
      ...state,
      paused: false,
      selected: state.actual.getSelected(),
      matrix: state.actual.getGame().matrix,
    }
  }

  if (action.type === types.ToggleErrors) {
    return {
      ...state,
      showErrors: !state.showErrors,
      paused: false,
    }
  }

  if (action.type === types.Left) {
    if (!state.actual) return state

    state.actual.left()

    return {
      ...state,
      paused: false,
      selected: state.actual.getSelected(),
      matrix: state.actual.getGame().matrix,
    }
  }

  if (action.type === types.Right) {
    if (!state.actual) return state

    state.actual.right()

    return {
      ...state,
      paused: false,
      selected: state.actual.getSelected(),
      matrix: state.actual.getGame().matrix,
    }
  }

  if (action.type === types.Down) {
    if (!state.actual) return state

    state.actual.down()

    return {
      ...state,
      paused: false,
      selected: state.actual.getSelected(),
      matrix: state.actual.getGame().matrix,
    }
  }

  if (action.type === types.StoreGames) {
    return {
      ...state,
      games: action.payload.games,
    }
  }

  if (action.type === types.Reset) {
    return {
      ...initialState,
      games: state.games,
    }
  }

  if (action.type === types.StoreGameAndContinuePlaying) {
    const game = PlayService.load(action.payload.game)

    return {
      ...state,
      showErrors: true,
      actual: game,
      time: game.getGame().duration || 0,
      matrix: game.getGame().matrix,
      errors: game.getNumberErrors(),
    }
  }

  if (action.type === types.Undo) {
    const game = state.actual

    if (!game) return state

    game.setUndo()

    return {
      ...state,
      paused: false,
      matrix: game.getGame().matrix,
    }
  }

  if (action.type === types.RecoverOneLifeWithAds) {
    const game = state.actual

    if (!game) return state

    game.recoverOneLife()

    return {
      ...state,
      errors: game.getNumberErrors(),
    }
  }

  if (action.type === types.Tick) {
    if (state.paused) return state

    const game = state.actual

    if (!game) return state

    game.tick()

    return {
      ...state,
      time: game.duration || 0,
    }
  }

  if (action.type === types.Resume) {
    return {
      ...state,
      paused: false,
    }
  }

  if (action.type === types.Pause) {
    const game = state.actual as PlayService

    game.unSelect()

    return {
      ...state,
      paused: true,
      selected: null,
      matrix: game.getGame().matrix,
    }
  }

  if (action.type === types.SelectCel) {
    const game = state.actual as PlayService

    game.selectCel(action.payload.id)

    return {
      ...state,
      paused: false,
      matrix: game.getGame().matrix,
      selected: action.payload.id,
    }
  }

  if (action.type === types.SelectGame) {
    const id = Math.round(Math.random() * (state.games.length - 1))
    const game = state.games[id]

    if (!game) throw new Error(`game has not be selected`)

    const play = new PlayService(game, {
      lifePowerUp: action.payload.lifePowerUp || false,
    })

    return {
      ...initialState,
      paused: false,
      stats: { ...initialState.stats },
      games: state.games,
      showErrors: true,
      win: false,
      actual: play,
      matrix: play.getGame().matrix,
      errors: play.getNumberErrors(),
    }
  }

  if (action.type === types.ChangeCel) {
    const game = state.actual

    if (!game) return state

    game?.updateCel(state.selected as string, action.payload.value as number)

    const errors = game.getNumberErrors()
    const winner = game.isWinner()

    return {
      ...state,
      errors,
      paused: winner,
      matrix: game?.getGame().matrix,
      win: winner,
    }
  }

  if (action.type === types.ChangeDraft) {
    const game = state.actual

    if (!game) return state

    game.changeDraft(action.payload.value || 0)

    return {
      ...state,
      paused: false,
      matrix: game?.getGame().matrix,
    }
  }

  if (action.type === types.ToggleDraftMode) {
    return {
      ...state,
      paused: false,
      draftMode: !state.draftMode,
    }
  }

  if (action.type === types.ShowClue) {
    const game = state.actual

    if (!game) return state

    game.showClue()

    const winner = game.isWinner()

    return {
      ...state,
      paused: winner,
      matrix: game.getGame().matrix,
      win: winner,
    }
  }

  if (action.type === types.StoreStatsAndCreateShareableLink) {
    const game = state.actual

    if (!game) return state

    const stats = game.getStats()

    return {
      ...state,
      stats: {
        ...stats,
      },
    }
  }

  return state
}
