import React, { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import {
  closeSubscriptionSuccessModal,
  SUBSCRIPTION_MODAL_SUCCESS_HASH,
} from "../../utils/drawerUtils"
import { Confetti } from "../Confetti/Confetti"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"

export type Props = {}

export const SubscriptionSuccessModal: React.FC<Props> = (props) => {
  const location = useLocation()

  const isOpen =
    location.href?.includes(SUBSCRIPTION_MODAL_SUCCESS_HASH) || false

  const onClose = () => closeSubscriptionSuccessModal()

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className={`fixed inset-0 z-50 overflow-y-auto`}>
        <div className="min-h-screen sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-75"
              onClick={onClose}
            />
          </Transition.Child>

          <Confetti show={isOpen} respawn />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="max-w-xl flex md:items-center items-end h-screen md:py-2 mx-auto">
              <div className="bg-gradient-to-b overflow-hidden relative from-violet-900 rounded-t-lg md:rounded-lg to-slate-900 shadow-xl">
                <div className="h-full overflow-auto no-scroll-bar p-8 px-4 md:p-8">
                  <div className="rounded flex-shrink-0">
                    <div className="text-7xl text-center">🎉</div>
                  </div>
                  <div className="mt-8">
                    <p className="font-display text-center text-white font-semibold text-xl">
                      <FormattedMessage id="premium/success-modal/title" />
                    </p>
                    <p className="text-center text-slate-50">
                      <FormattedMessage id="premium/success-modal/description" />
                    </p>
                  </div>

                  <div className="w-full p-4 mt-8 rounded-xl bg-white bg-opacity-5">
                    <div className="flex">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          ℹ️
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/success-modal/how-to-cancel/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/success-modal/how-to-cancel/description" />
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-2">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          💬
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/success-modal/contact-us/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/success-modal/contact-us/description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-auto p-4 flex-shrink-0">
                  <div className="mx-auto">
                    <div className="flex items-center justify-center">
                      <button
                        onClick={onClose}
                        className="transition-all px-4 ease-in-out duration-300 font-display font-semibold py-2 mt-2 text-white"
                      >
                        <FormattedMessage id="premium/success-modal/close" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  )
}
