import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../../entities/LeaderboardEntity"

import { PointsStepEntity } from "../../services/LevelAndPointsService"

export const Fetching = "REDUX_POINTS_FETCHING"
export interface FetchingAction {
  type: typeof Fetching
}

export const FetchEnd = "REDUX_POINTS_FETCH_END"
export interface FetchEndAction {
  type: typeof FetchEnd
}

export const Store = "REDUX_POINTS_STORE"
export interface StoreAction {
  type: typeof Store
  payload: {
    level: number
    points: number
    nextLevelPoints: number
    steps: Array<PointsStepEntity>
  }
}

export const SetFetchingLeaderboard = "REDUX_POINTS_SET_FETCHING_LEADERBOARD"
export interface SetFetchingLeaderboardAction {
  type: typeof SetFetchingLeaderboard
  payload: {
    fetching: boolean
  }
}

export const StoreLeaderboard = "REDUX_POINTS_STORE_LEADERBOARD"
export interface StoreLeaderboardAction {
  type: typeof StoreLeaderboard
  payload: {
    leaderboard: LeaderboardEntity[]
    meta: MetaLeaderboardEntity
    userPoints: number
  }
}

export const leaderboardOpenPromotionModal = "leaderboardOpenPromotionModal"
export interface leaderboardOpenPromotionModalAction {
  type: typeof leaderboardOpenPromotionModal
  payload: {
    type: "promotion" | "relegation" | "egation"
    actualLeague: number
    lastLeague: number
  }
}

export type StatsActionTypes =
  | StoreAction
  | SetFetchingLeaderboardAction
  | FetchEndAction
  | FetchingAction
  | StoreLeaderboardAction
  | leaderboardOpenPromotionModalAction
