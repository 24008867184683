import { Transition } from "@headlessui/react"
import React from "react"
import { NotificationCoinEntity } from "../../entities/NotificationEntity"
import {
  connector,
  ContainerProps,
} from "./containers/NotificationCoins.containers"

const Notification: React.FC<{
  amount: number
}> = (props) => {
  return (
    <Transition
      appear={true}
      show
      as={React.Fragment}
      enter="transform ease-in-out duration-300 transition"
      enterFrom="-translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-100"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-full"
    >
      <div className="p-4 mt-4 bg-white rounded-md border border-slate-200 flex items-center text-emerald-500">
        <img width={"24px"} height={"28px"} src="/emerald.svg" />
        <span className="font-semibold font-display ml-1">
          {props.amount > 0 ? "+" : ""}
          {props.amount}
        </span>
      </div>
    </Transition>
  )
}

export const Wrapper: React.FC<{
  notifications: NotificationCoinEntity[]
}> = (props) => {
  return (
    <div className="fixed top-0 right-16 z-50 pointer-events-none">
      {props.notifications.map(({ amount, id }) => (
        <Notification key={id} amount={amount} />
      ))}
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const NotificationCoins = connector(Container)
