import { StatForShareableLink, StatEntity } from "../entities/StatEntity"

export type PointsStepEntity = {
  type:
    | "base"
    | "no-error"
    | "three-errors"
    | "no-hint"
    | "hint-used"
    | "daily-bonus"
    | "total"
    | "launch-bonus"
    | "ten-errors"
  value: "x2" | "x3" | "x4" | "x1.5" | "x0.5" | "+5" | "x0" | number
}

export class LevelAndPointsService {
  static getPointsByDifficulty(difficulty: StatEntity["level"]) {
    if (difficulty === "easy") return 10
    if (difficulty === "medium") return 20
    if (difficulty === "hard") return 40
    if (difficulty === "expert") return 80
    if (difficulty === "evil") return 160
  }

  static getPoints(
    stats: StatForShareableLink,
    bonus?: Array<PointsStepEntity>
  ): {
    steps: Array<PointsStepEntity>
    total: number
  } {
    const base = this.getPointsByDifficulty(stats.level)
    const steps: PointsStepEntity[] = [{ type: "base", value: base as number }]

    if (stats.errors <= 0) steps.push({ type: "no-error", value: "x2" })
    if (stats.errors >= 3 && stats.errors < 10)
      steps.push({ type: "three-errors", value: -base + 5 })
    if (stats.errors >= 10) steps.push({ type: "ten-errors", value: "x0" })
    if (stats.clues >= 1) steps.push({ type: "hint-used", value: "x0.5" })

    steps.push(...(bonus || []))

    const total = steps.reduce((acc, { value }) => {
      if (value === "x1.5") return acc * 1.5
      if (value === "x2") return acc * 2
      if (value === "x3") return acc * 3
      if (value === "x4") return acc * 4
      if (value === "x0.5") return acc * 0.5
      if (value === "x0") return 0
      return Number(value) + acc
    }, 0)

    return {
      steps,
      total: Math.round(total),
    }
  }

  static getNextLevel(level: number) {
    const exponent = 1.55
    const baseXP = 50
    return Math.floor(baseXP * Math.pow(level, exponent))
  }

  static getActualLevel(points: number) {
    for (let level = 1; level < 1000; level++) {
      if (this.getNextLevel(level) > points) return level
    }

    return 1000
  }

  static apply(params: {
    actualPoints: number
    stats: StatForShareableLink
    bonus?: Array<PointsStepEntity>
  }) {
    const { steps, total } = this.getPoints(params.stats, params.bonus)
    const totalPoints = params.actualPoints + total
    const actualLevel = this.getActualLevel(params.actualPoints)
    const nextLevel = this.getNextLevel(actualLevel)
    const levelUp = nextLevel <= totalPoints

    return {
      level: levelUp ? actualLevel + 1 : actualLevel,
      actualLevel,
      levelUp,
      steps,
      totalPoints,
      pointsEarned: total,
    }
  }
}
