import * as types from "./types"
import { AchievementWithRewardEntity } from "../../entities/AchievementEntity"
import { AchievementsCheckerService } from "../../services/AchievementsCheckerService"

interface StatsState {
  fetching: boolean
  achievements: AchievementWithRewardEntity[]
  achieved: AchievementWithRewardEntity[]
}

const initialState: StatsState = {
  fetching: false,
  achievements: new AchievementsCheckerService([]).getArray(),
  achieved: [],
}

export function achievementsReducer(
  state = initialState,
  action: types.AchievementsActionTypes
): StatsState {
  if (action.type === types.Fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === types.FetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === types.clearAchieved) {
    return {
      ...state,
      achieved: [],
    }
  }

  if (action.type === types.Store) {
    const service = new AchievementsCheckerService(action.payload.achievements)

    return {
      ...state,
      achievements: service.getArray(),
    }
  }

  if (action.type === types.incrementAll) {
    const service = new AchievementsCheckerService([...state.achievements])

    service.incrementAll(action.payload.parent)

    return {
      ...state,
      achievements: service.getArray(),
      achieved: [...state.achieved, ...service.getAchieved()],
    }
  }

  if (action.type === types.setToAll) {
    const service = new AchievementsCheckerService([...state.achievements])

    service.setToAll(action.payload)

    return {
      ...state,
      achievements: service.getArray(),
      achieved: [...state.achieved, ...service.getAchieved()],
    }
  }

  return state
}
