import { CoinEntity } from "../entities/CoinEntity"
import { ICoinsRepository } from "../interfaces/ICoinsRepository"

export class InMemoryCoinsRepository implements ICoinsRepository {
  private coins: CoinEntity = { amount: 0 }

  async getTotalAmount(params: { userId: string }): Promise<CoinEntity> {
    return this.coins
  }

  async spend(params: { userId: string; amount: number }): Promise<CoinEntity> {
    this.coins = {
      ...this.coins,
      amount: this.coins.amount - params.amount,
    }

    return this.coins
  }

  async add(params: { userId: string; amount: number }): Promise<CoinEntity> {
    this.coins = { amount: params.amount + this.coins.amount }

    return this.coins
  }
}
