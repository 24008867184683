import React from "react"

export const H1 = ({ children, id }) => (
  <div className="px-4 container mx-auto">
    <h1
      id={id}
      className="max-w-2xl font-display mx-auto mt-12 text-4xl font-bold"
    >
      {children}
    </h1>
  </div>
)

export const H2 = (props) => (
  <div className="container mx-auto">
    <h2
      id={props.id}
      className={`max-w-3xl px-4 mx-auto py-2 font-display pt-10 text-4xl font-bold tracking-tight text-slate-900`}
    >
      {props.children}
    </h2>
  </div>
)

export const H3 = ({ children, id }) => (
  <div className="container mx-auto">
    <h3
      id={id}
      className="max-w-3xl px-4 mx-auto font-display pt-10 py-2 text-3xl font-bold text-slate-900"
    >
      {children}
    </h3>
  </div>
)

export const H4 = ({ children, id }) => (
  <div className="container mx-auto">
    <h4
      id={id}
      className="max-w-3xl px-4 mx-auto font-display py-2 pt-10 text-3xl font-bold text-slate-900"
    >
      {children}
    </h4>
  </div>
)

export const H5 = ({ children, id }) => (
  <div className="container mx-auto">
    <h5
      id={id}
      className="max-w-3xl px-4 mx-auto font-display py-2 pt-10 text-2xl font-bold text-slate-900"
    >
      {children}
    </h5>
  </div>
)

export const H6 = ({ children, id }) => (
  <div className="container mx-auto">
    <h6
      id={id}
      className="max-w-3xl px-4 mx-auto font-display py-2 pt-10 text-xl font-bold text-slate-900"
    >
      {children}
    </h6>
  </div>
)
