import { CoinEntity } from "../entities/CoinEntity"
import { ICoinsRepository } from "../interfaces/ICoinsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseCoinsRepository
  extends FirebaseUtils
  implements ICoinsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "coins"

  async getTotalAmount(params: { userId: string }): Promise<CoinEntity> {
    const firestore = this.firebase.database()
    const collection = firestore.collection(this.collection).doc(params.userId)

    const response = await collection.get()
    const data = response.data() as CoinEntity

    return {
      amount: data?.amount || 0,
    }
  }

  async spend(params: { userId: string; amount: number }): Promise<CoinEntity> {
    const firestore = this.firebase.database()
    const total = await this.getTotalAmount(params)
    const collection = firestore.collection(this.collection).doc(params.userId)
    const amountAfterSpend = Math.max(total.amount - params.amount, 0)
    const entity = {
      amount: amountAfterSpend,
    }

    try {
      await collection.update(entity)
    } catch (e) {
      await collection.set(entity)
    }

    return entity
  }

  async add(params: { userId: string; amount: number }): Promise<CoinEntity> {
    const firestore = this.firebase.database()
    const total = await this.getTotalAmount(params)
    const collection = firestore.collection(this.collection).doc(params.userId)
    const amountAfter = total.amount + params.amount
    const entity = {
      amount: amountAfter,
    }

    try {
      await collection.update(entity)
    } catch (e) {
      await collection.set(entity)
    }

    return entity
  }
}
