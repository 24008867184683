import { AuthenticateReturnErrorType, SendSignInLinkToEmailErrorType } from "../../../interfaces/IAuthRepository"
import { FlowsSigninEmailStateCodesTypes } from "../reducers"

export const FlowsSigninProcessFetchingType =
  "REDUX_AUTH_FLOWS_SIGNIN_PROCESS_FETCHING"
export interface FlowsSigninProcessFetchingAction {
  type: typeof FlowsSigninProcessFetchingType
}

export const FlowsSigninProcessFetchEndType =
  "REDUX_AUTH_FLOWS_SIGNIN_PROCESS_FETCH_END"
export interface FlowsSigninProcessFetchEndAction {
  type: typeof FlowsSigninProcessFetchEndType
}

export const FlowsSigninProcessErrorSetType =
  "REDUX_AUTH_FLOWS_SIGNIN_PROCESS_ERROR_SET"
export interface FlowsSigninProcessErrorSetAction {
  payload: { error: SendSignInLinkToEmailErrorType }
  type: typeof FlowsSigninProcessErrorSetType
}

export const FlowsSigninProcessSucceedUpdateType =
  "REDUX_AUTH_FLOWS_SIGNIN_PROCESS_SUCCEED_UPDATE"
export interface FlowsSigninProcessSucceedUpdateAction {
  payload: { state: boolean }
  type: typeof FlowsSigninProcessSucceedUpdateType
}

export const FlowsSigninFormEmailUpdateType =
  "REDUX_AUTH_FLOWS_SIGNIN_FORM_EMAIL_UPDATE"
export interface FlowsSigninFormEmailUpdateAction {
  type: typeof FlowsSigninFormEmailUpdateType
  payload: { email: string }
}

export const FlowsSigninFormEmailCheckType =
  "REDUX_AUTH_FLOWS_SIGNIN_FORM_EMAIL_CHECK"
export interface FlowsSigninFormEmailCheckAction {
  type: typeof FlowsSigninFormEmailCheckType
}

export const FlowsSigninFormEmailUpdateStateType =
  "REDUX_AUTH_FLOW_SIGNIN_FORM_EMAIL_UPDATE_STATE"
export interface FlowsSigninFormEmailUpdateStateAction {
  type: typeof FlowsSigninFormEmailUpdateStateType
  payload: {
    state: FlowsSigninEmailStateCodesTypes
  }
}

export const FlowsSigninFormEmailUpdateFocusType =
  "REDUX_AUTH_FLOW_SIGNIN_FORM_EMAIL_FOCUS_UPDATE"
export interface FlowsSigninFormEmailUpdateFocusAction {
  type: typeof FlowsSigninFormEmailUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsSigninStepsNextType = "REDUX_AUTH_FLOW_SIGNIN_STEPS_NEXT"
export interface FlowsSigninStepsNextAction {
  type: typeof FlowsSigninStepsNextType
}

export const FlowsSigninStepsPreviousType =
  "REDUX_AUTH_FLOW_SIGNIN_STEPS_PREVIOUS"
export interface FlowsSigninStepsPreviousAction {
  type: typeof FlowsSigninStepsPreviousType
}

export type AuthSigninActionTypes =
  | FlowsSigninProcessErrorSetAction
  | FlowsSigninStepsPreviousAction
  | FlowsSigninStepsNextAction
  | FlowsSigninFormEmailUpdateAction
  | FlowsSigninFormEmailCheckAction
  | FlowsSigninProcessFetchingAction
  | FlowsSigninProcessFetchEndAction
  | FlowsSigninFormEmailUpdateStateAction
  | FlowsSigninFormEmailUpdateFocusAction
  | FlowsSigninProcessSucceedUpdateAction
