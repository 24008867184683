import * as types from "./types"

interface GlobalState {
  ads: {
    show: boolean
    isAlreadyFetched: boolean
  }
  isPremium: boolean
}

const initialState: GlobalState = {
  ads: {
    isAlreadyFetched: false,
    show: false,
  },
  isPremium: false,
}

export function globalReducer(
  state = initialState,
  action: types.GlobalActionTypes
): GlobalState {
  if (action.type === types.storeAds) {
    return {
      ...state,
      ads: {
        isAlreadyFetched: true,
        show: action.payload.showAds,
      },
    }
  }
  if (action.type === types.storeIsPremium) {
    return {
      ...state,
      isPremium: action.payload.isPremium,
    }
  }

  return state
}
