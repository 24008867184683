import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { LevelAndPointsService } from "../../services/LevelAndPointsService"
import { Modules } from "../../interfaces/IModule"
import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../../entities/LeaderboardEntity"

export const fetching = (): types.StatsActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.StatsActionTypes => ({
  type: types.FetchEnd,
})

export const store = (
  payload: types.StoreAction["payload"]
): types.StatsActionTypes => ({
  type: types.Store,
  payload,
})

export const setFetchingLeaderboard = (
  payload: types.SetFetchingLeaderboardAction["payload"]
): types.StatsActionTypes => ({
  type: types.SetFetchingLeaderboard,
  payload,
})

export const storeLeaderboard = (
  payload: types.StoreLeaderboardAction["payload"]
): types.StatsActionTypes => ({
  type: types.StoreLeaderboard,
  payload,
})

export const leaderboardOpenPromotionModal = (
  payload: types.leaderboardOpenPromotionModalAction["payload"]
): types.StatsActionTypes => ({
  type: types.leaderboardOpenPromotionModal,
  payload,
})

export const $shouldShowLeaguePromotion = async (params: {
  di: Modules
  meta: MetaLeaderboardEntity
  leaderboard: LeaderboardEntity
  dispatcher: any
}) => {
  const key = "leaderboard/meta"
  const localStorage = await params.di.LocalStorageRepository.get<{
    id: string
    league: number
  }>(key)

  if (!localStorage) {
    await params.di.LocalStorageRepository.store(key, {
      id: params.meta.start.toISOString(),
      league: params.leaderboard.league,
    })
  } else {
    if (localStorage.id === params.meta.start.toISOString()) return

    const type =
      params.leaderboard.league === localStorage.league
        ? "egation"
        : params.leaderboard.league > localStorage.league
        ? "promotion"
        : "relegation"

    params.dispatcher(
      leaderboardOpenPromotionModal({
        actualLeague: params.leaderboard.league,
        lastLeague: localStorage.league,
        type,
      })
    )

    await params.di.LocalStorageRepository.store(key, {
      id: params.meta.start.toISOString(),
      league: params.leaderboard.league,
    })

    params.di.LocationService.navigate(
      params.di.LocationService.getPathname() + "#league-promotion-modal=open"
    )

    params.di.AnalyticsService.send({
      category: "leaderboard",
      action: type,
    })
  }
}

export const $fetchLeaderboard =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (!auth.user?.id) return false

    dispatcher(setFetchingLeaderboard({ fetching: true }))

    const response = await di.LeaderboardRepository.getUserLeague({
      userId: auth.user.id,
    })

    const meta = await di.LeaderboardRepository.getMeta()

    if (response.length === 0) {
      const bracketId = 0

      await di.LeaderboardRepository.storeUserOnLeague({
        points: 0,
        league: 0,
        bracket: bracketId,
        userId: auth.user.id,
        avatar: auth.user.avatar,
        username: auth.user.username,
      })

      const league = await di.LeaderboardRepository.getUserLeague({
        userId: auth.user.id,
      })

      const userPoints =
        league.find((board) => {
          return board.userId === auth.user.id
        })?.points || 0

      dispatcher(storeLeaderboard({ leaderboard: league, meta, userPoints }))
      dispatcher(setFetchingLeaderboard({ fetching: false }))
      $shouldShowLeaguePromotion({
        di: di,
        dispatcher,
        meta,
        leaderboard: league[0],
      })
    } else {
      const userPoints =
        response.find((board) => {
          return board.userId === auth.user.id
        })?.points || 0

      dispatcher(storeLeaderboard({ leaderboard: response, meta, userPoints }))
      dispatcher(setFetchingLeaderboard({ fetching: false }))
      $shouldShowLeaguePromotion({
        di: di,
        dispatcher,
        meta,
        leaderboard: response[0],
      })
    }
  }

export const $fetch =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(fetching())

    const { points } = auth.user?.id
      ? await di.PointsRepository.fetch({
          userId: auth.user.id,
        })
      : { points: 0 }

    const level = LevelAndPointsService.getActualLevel(points)
    const nextLevelPoints = LevelAndPointsService.getNextLevel(level)

    dispatcher(
      store({
        level,
        nextLevelPoints,
        points,
        steps: [],
      })
    )
    dispatcher(fetchEnd())
  }

export const $sync =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (auth.user?.id)
      di.PointsRepository.sync({
        userId: auth.user.id,
        avatar: auth.user.avatar,
        username: auth.user.username,
      })
  }
