import { GameEntity } from "../../entities/GameEntity"

export const StoreGames = "REDUX_PRINT_STORE_GAMES"
export interface StoreGamesAction {
  type: typeof StoreGames
  payload: { games: GameEntity[] }
}

export const SelectGame = "REDUX_PRINT_SELECT_GAME"
export interface SelectGameAction {
  type: typeof SelectGame
}

export type PrintActionTypes = SelectGameAction | StoreGamesAction
