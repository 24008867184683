import { baseAchievements } from "../database/success"
import { AchievementWithRewardEntity } from "../entities/AchievementEntity"

type AchivementType = {
  id: string
  value: number
  goal: number
  parent: string
}

type AchivementValueType = {
  parent: string
  value: number
}

export class AchievementsCheckerService {
  private achievements: { [x: string]: AchievementWithRewardEntity } = {}
  private achieved: AchievementWithRewardEntity[] = []

  constructor(props: AchivementValueType[]) {
    baseAchievements.forEach((achievement) => {
      this.achievements[achievement.id] = {
        ...achievement,
        value:
          props.find(({ parent }) => parent === achievement.parent)?.value ||
          achievement.value,
      }
    })
  }

  private incrementOne(id: string) {
    if (!this.achievements[id])
      throw new Error(
        "you are trying to increment an achievement that not exists"
      )

    const achievement = this.achievements[id]
    const goal = achievement.goal
    const value = achievement.value

    this.achievements[id].value++

    if (achievement.value >= goal && value < goal) {
      this.achieved.push(this.achievements[id])
    }
  }

  private setToOne(id: string, value: number) {
    if (!this.achievements[id])
      throw new Error(
        "you are trying to increment an achievement that not exists"
      )

    const achievement = this.achievements[id]
    const goal = achievement.goal
    const oldValue = achievement.value

    this.achievements[id].value = value

    if (achievement.value >= goal && value < goal) {
      this.achieved.push(this.achievements[id])
    }
  }

  setToAll(params: { parent: string; value: number }) {
    const entries = Object.entries<AchivementType>(this.achievements)

    entries.forEach(([id, achievement]) => {
      if (achievement.parent !== params.parent) return
      this.setToOne(id, params.value)
    })
  }

  incrementAll(parent: string) {
    const entries = Object.entries<AchivementType>(this.achievements)

    entries.forEach(([id, achievement]) => {
      if (achievement.parent !== parent) return
      this.incrementOne(id)
    })
  }

  valueOf() {
    return this.achievements
  }

  getArray() {
    return Object.entries(this.achievements).map(([id, achievement]) => {
      return achievement
    })
  }

  getAchieved() {
    return this.achieved
  }
}
