import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: { respawn: boolean; show?: boolean }
) => {
  return {
    isOpen:
      state.achievements.achieved.length > 0 || state.play.win || props.show,
    respawn: props.respawn,
  }
}

export const connector = connect(mapState)
export type ContainerProps = ConnectedProps<typeof connector>
