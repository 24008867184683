import { PointsEntity } from "../entities/PointsEntity"
import {
  IPointsRepository,
  PointsUpdateProfileReturnType,
} from "../interfaces/IPointsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebasePointsRepository
  extends FirebaseUtils
  implements IPointsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "points"

  async fetch(params: { userId: string }): Promise<{ points: number }> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!response.exists) return { points: 0 }

    const data = response.data()

    return {
      points: data?.points || 0,
    }
  }

  async getUser(params: { userId: string }): Promise<PointsEntity | null> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!response.exists) return null

    const data = response.data()

    return data as PointsEntity
  }

  async fetchAll(params: { limit: number }): Promise<PointsEntity[]> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .orderBy("points", "desc")
      .limit(params.limit)
      .get()

    const data = this.mapQuerySnapshot<PointsEntity>(response)

    return data
  }

  async store(params: PointsEntity): Promise<PointsEntity> {
    const firestore = this.firebase.database()
    const doc = firestore.collection(this.collection).doc(params.userId)
    const getDocument = await doc.get()
    const data = getDocument.data() as PointsEntity

    await doc.set({
      ...params,
      points: (data.points || 0) + (params.points || 0),
    })

    return params
  }

  async updateProfile(params: {
    username?: string
    avatar?: string
  }): Promise<PointsUpdateProfileReturnType> {
    const firestore = this.firebase.database()
    const instance = this.firebase.getInstance()

    const user = instance.auth().currentUser

    if (!user) return { success: false, error: "not-logged" }

    const doc = firestore.collection(this.collection).doc(user?.uid)

    try {
      await doc.update(params)
    } catch (e) {
      await doc.set(params)
    }

    return { success: true }
  }

  async sync(params: {
    userId: string
    avatar: string
    username: string
  }): Promise<{ succeed: boolean }> {
    try {
      const points = Number(window.localStorage.getItem("points")) || 0

      if (!points) return { succeed: true }

      const exists = await this.fetch(params)

      if (exists.points) return { succeed: true }

      await this.store({ ...params, points })

      window.localStorage.removeItem("points")

      return { succeed: true }
    } catch (e) {
      console.error(e)
      return { succeed: false }
    }
  }
}
