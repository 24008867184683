import { AchievementEntity } from "../../entities/AchievementEntity"
import { PointsEntity } from "../../entities/PointsEntity"
import { PointsStepEntity } from "../../services/LevelAndPointsService"

export const Fetching = "REDUX_ACHIEVEMENTS_FETCHING"
export interface FetchingAction {
  type: typeof Fetching
}

export const FetchEnd = "REDUX_ACHIEVEMENTS_FETCH_END"
export interface FetchEndAction {
  type: typeof FetchEnd
}

export const Store = "REDUX_ACHIEVEMENTS_STORE"
export interface StoreAction {
  type: typeof Store
  payload: {
    achievements: AchievementEntity[]
  }
}

export const clearAchieved = "REDUX_ACHIEVEMENTS_CLEAR_ACHIEVED"
export interface clearAchievedAction {
  type: typeof clearAchieved
}

export const SetFetchingLeaderboard =
  "REDUX_ACHIEVEMENTS_SET_FETCHING_LEADERBOARD"
export interface SetFetchingLeaderboardAction {
  type: typeof SetFetchingLeaderboard
  payload: {
    fetching: boolean
  }
}

export const StoreLeaderboard = "REDUX_ACHIEVEMENTS_STORE_LEADERBOARD"
export interface StoreLeaderboardAction {
  type: typeof StoreLeaderboard
  payload: PointsEntity[]
}

export const incrementAll = "REDUX_ACHIEVEMENTS_INCREMENT_ALL"
export interface incrementAllAction {
  type: typeof incrementAll
  payload: { parent: string }
}

export const setToAll = "REDUX_ACHIEVEMENTS_SET_TO_ALL"
export interface setToAllAction {
  type: typeof setToAll
  payload: { parent: string; value: number }
}

export type AchievementsActionTypes =
  | StoreAction
  | incrementAllAction
  | setToAllAction
  | SetFetchingLeaderboardAction
  | FetchEndAction
  | FetchingAction
  | clearAchievedAction
  | StoreLeaderboardAction
