export const FlowsUsernameProcessSetFetching =
  "AUTH_FLOWS_USERNAME_PROCESS_FETCHING_SET"
export interface FlowsUsernameProcessSetFetchingAction {
  type: typeof FlowsUsernameProcessSetFetching
  payload: { fetching: boolean }
}

export const FlowsUsernameProcessError = "AUTH_FLOWS_USERNAME_PROCESS_ERROR_SET"
export interface FlowsUsernameProcessErrorAction {
  payload: { error: string }
  type: typeof FlowsUsernameProcessError
}

export const FlowsUsernameProcessUpdateValue =
  "AUTH_FLOWS_USERNAME_PROCESS_UPDATE_VALUE"
export interface FlowsUsernameProcessUpdateValueAction {
  payload: { value: string }
  type: typeof FlowsUsernameProcessUpdateValue
}

export const FlowsUsernameProcessSetModalOpenState =
  "AUTH_FLOWS_USERNAME_PROCESS_SET_MODAL_OPEN_STATE"
export interface FlowsUsernameProcessSetModalOpenStateAction {
  payload: { isOpen: boolean }
  type: typeof FlowsUsernameProcessSetModalOpenState
}

export type AuthUsernameActionTypes =
  | FlowsUsernameProcessSetFetchingAction
  | FlowsUsernameProcessErrorAction
  | FlowsUsernameProcessUpdateValueAction
  | FlowsUsernameProcessSetModalOpenStateAction
