import { NewsletterEntity } from "../entities/NewsletterEntity"
import { INewsletterRepository } from "../interfaces/INewsletterRepository"

export class LocalStorageNewsletterRepository implements INewsletterRepository {
  private newsletters: Map<string, NewsletterEntity> = new Map()

  async get(params: { userId: string }): Promise<NewsletterEntity | null> {
    return this.newsletters.get(params.userId) || null
  }

  update(params: {
    userId: string
    newsletter: NewsletterEntity
  }): Promise<NewsletterEntity> {
    this.newsletters.set(params.userId, params.newsletter)

    return this.get(params)
  }
}
