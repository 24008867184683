import { SubscriptionState } from "../../entities/SubscriptionEntity"
import * as types from "./types"

interface PricingState {
  state: SubscriptionState
  isPremium: boolean
}

const initialState: PricingState = {
  state: "not-found",
  isPremium: false,
}

export function pricingReducer(
  state = initialState,
  action: types.PricingActionTypes
): PricingState {
  if (action.type === types.storeState) {
    return {
      ...state,
      state: action.payload.state,
      isPremium:
        action.payload.state === "active" ||
        action.payload.state === "cancelled",
    }
  }

  return state
}
