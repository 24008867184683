import axios from "axios"
import { SubscriptionState } from "../entities/SubscriptionEntity"
import { IPaymentService } from "../interfaces/IPaymentService"

export class LemonSqueezyPaymentService implements IPaymentService {
  async getSubscriptionState(params: {
    email: string
  }): Promise<{ status: SubscriptionState }> {
    const response = await axios.post<{
      status: SubscriptionState
    }>(
      "https://us-central1-sudoku-academy.cloudfunctions.net/app/customers/status",
      {
        email: params.email,
      }
    )

    return response.data
  }

  async unsubscribe(params: {
    email: string
  }): Promise<{ status: SubscriptionState }> {
    const response = await axios.post<{
      status: SubscriptionState
    }>(
      "https://us-central1-sudoku-academy.cloudfunctions.net/app/customers/cancel",
      {
        email: params.email,
      }
    )

    return response.data
  }

  openSubscriptionDialog(options: {
    email: string
    userId: string
    productId: string | number
  }): Promise<{ status: "success" | "closed" }> {
    return new Promise((resolve, reject) => {
      const params = {
        coupon: null,
      }

      const url = `https://sudoku-academy.lemonsqueezy.com/checkout/buy/551cb671-99e8-40ac-84b1-584cedb1f970?checkout[email]=${options.email}${params.coupon ? `&checkout[discount_code]=${params.coupon}` : ""}&logo=1&embed=0&test-mode=0`

      window.location.href = url
    })
  }
}
