import { ILocalStorageRepository } from "../interfaces/ILocalStorageRepository"

export class BrowserLocalStorageRepository implements ILocalStorageRepository {
  async store(name: string, value: { [x: string]: any }) {
    try {
      window.localStorage.setItem(name, JSON.stringify(value))
    } catch (e) {}
  }

  async get<T>(name: string) {
    try {
      return (JSON.parse(window.localStorage.getItem(name)) as T) || null
    } catch (e) {
      return null
    }
  }
}
