import { AnalyticsEntity } from "../entities/AnalyticsEntity"
import { IAnalyticsService } from "../interfaces/IAnalyticsService"

export class AmplitudeAnalyticsService implements IAnalyticsService {
  send(analytics: AnalyticsEntity) {
    const { category, action, data } = analytics
    try {
      window.amplitude
        .getInstance()
        .logEvent(`${category}/${action}`, data || {})
    } catch (e) {}
  }

  async authenticate(params: { id: string }): Promise<void> {
    try {
      window.amplitude.getInstance().setUserId(params.id)
    } catch (e) {}
  }

  async logout(): Promise<void> {
    try {
      window.amplitude.getInstance().setUserId(null)
    } catch (e) {}
  }
}
