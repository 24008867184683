import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  AuthenticateReturnErrorType,
  AuthenticateWithGoogleReponse,
  AuthenticateWithLinkReturnErrorType,
  AuthenticateWithLinkReturnType,
  IAuthRepository,
  IsAuthenticatedReturnType,
  SendSignInLinkToEmailErrorType,
  SendSignInLinkToEmailType,
  UpdateProfileReturnType,
} from "../interfaces/IAuthRepository"

export class FirebaseAuthRepository
  extends FirebaseUtils
  implements IAuthRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async authenticateWithGoogle(): Promise<AuthenticateWithGoogleReponse> {
    const firebase = this.firebase.auth()
    const provider = this.firebase.getGoogleProvider()

    try {
      const response = await firebase.signInWithPopup(provider)
      const isNewUser = response.additionalUserInfo.isNewUser
      const avatarFromGoogle = response.user?.photoURL?.includes("google")

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar:
            isNewUser || avatarFromGoogle
              ? null
              : response.user?.photoURL || null,
          username:
            isNewUser || avatarFromGoogle
              ? null
              : response.user?.displayName || null,
        },
      }
    } catch (e) {
      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled"
      }

      const codes: {
        [key in typeof error.code]: any
      } = {
        "auth/user-disabled": "USER_DISABLED",
        "auth/expired-action-code": "EMAIL_BAD_FORMATTED",
        "auth/invalid-email": "EMAIL_BAD_FORMATTED",
      }

      return {
        error: codes[error.code],
        authenticated: false,
      }
    }
  }

  async isAuthenticated(): Promise<IsAuthenticatedReturnType> {
    return new Promise((resolve, reject) => {
      const firebase = this.firebase.getInstance()
      firebase.auth().onAuthStateChanged(function (user) {
        if (user)
          return resolve({
            authenticated: true,
            user: {
              id: user.uid,
              email: user.email as string,
              avatar: user.photoURL,
              username: user.displayName,
            },
          })
        return resolve({ authenticated: false })
      })
    })
  }

  async logout() {
    try {
      const firebase = this.firebase.getInstance()
      await firebase.auth().signOut()

      return { succeed: true }
    } catch (e) {
      return { succeed: true }
    }
  }

  async sendSignInLinkToEmail(params: {
    email: string
    lang: string
    destinationUrl: string
  }): Promise<SendSignInLinkToEmailType> {
    const firebase = this.firebase.getInstance()

    firebase.auth().languageCode = params.lang

    try {
      await firebase.auth().sendSignInLinkToEmail(params.email, {
        handleCodeInApp: true,
        url: params.destinationUrl,
      })

      return { succeed: true }
    } catch (e) {
      const error = e as {
        code:
          | "auth/argument-error"
          | "auth/invalid-email"
          | "auth/missing-android-pkg-name"
          | "auth/missing-continue-uri"
          | "auth/missing-ios-bundle-id"
          | "auth/invalid-continue-uri"
          | "auth/unauthorized-continue-uri"
      }

      const codes: {
        [key in typeof error.code]: SendSignInLinkToEmailErrorType
      } = {
        "auth/invalid-email": "INVALID_EMAIL",
        "auth/argument-error": "ERROR_SERVER",
        "auth/missing-android-pkg-name": "ERROR_SERVER",
        "auth/missing-continue-uri": "ERROR_SERVER",
        "auth/missing-ios-bundle-id": "ERROR_SERVER",
        "auth/invalid-continue-uri": "ERROR_SERVER",
        "auth/unauthorized-continue-uri": "ERROR_SERVER",
      }

      return {
        error: codes[error.code] || "ERROR_SERVER",
        sourceErrorCode: error.code,
        succeed: false,
      }
    }
  }

  async authenticateWithSigninLink(params: {
    email: string
    link: string
  }): Promise<AuthenticateWithLinkReturnType> {
    const firebase = this.firebase.getInstance()

    if (!firebase.auth().isSignInWithEmailLink(params.link))
      return {
        error: "EXPIRED_CODE",
        authenticated: false,
      }

    try {
      const response = await firebase
        .auth()
        .signInWithEmailLink(params.email, params.link)

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar: response.user?.photoURL || null,
          username: response.user?.displayName || null,
        },
      }
    } catch (e) {
      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled"
      }

      const codes: {
        [key in typeof error.code]: AuthenticateWithLinkReturnErrorType
      } = {
        "auth/expired-action-code": "EXPIRED_CODE",
        "auth/invalid-email": "EXPIRED_CODE",
        "auth/user-disabled": "USER_DISABLED",
      }

      return {
        error: codes[error.code] || "USER_DISABLED",
        authenticated: false,
        sourceErrorCode: error.code,
      }
    }
  }

  async updateProfile(params: {
    avatar?: string | undefined
    username?: string | undefined
  }): Promise<UpdateProfileReturnType> {
    const firebase = this.firebase.getInstance()

    const user = firebase.auth().currentUser

    if (!user) return { updated: false, error: "auth/not-authenticated" }

    try {
      const profile = {
        ...(params.username ? { displayName: params.username } : {}),
        ...(params.avatar ? { photoURL: params.avatar } : {}),
      }

      await user.updateProfile(profile)

      return {
        updated: true,
        user: {
          id: user.uid,
          email: user.email as string,
          avatar: user.photoURL as string,
          username: user.displayName as string,
          ...params,
        },
      }
    } catch (e) {
      const error = e as string

      return {
        updated: false,
        error: "auth/update-profile/error-server",
        sourceErrorCode: error,
      }
    }
  }
}
