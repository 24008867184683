import { StatEntity } from "../entities/StatEntity"
import {
  IStatsRepository,
  IStatsRepositoryPeriod,
} from "../interfaces/IStatsRepository"

export class InMemoryStatsRepository implements IStatsRepository {
  private stats: Map<StatEntity["id"], StatEntity> = new Map()

  async findAll(params: {
    period: IStatsRepositoryPeriod
    userId: string
  }): Promise<StatEntity[]> {
    return Array.from(this.stats.values())
  }

  async findToday(): Promise<StatEntity[]> {
    return Array.from(this.stats.values())
  }

  async store(params: {
    stats: StatEntity
    userId: string
  }): Promise<StatEntity> {
    this.stats.set(params.stats.id, params.stats)
    return params.stats
  }

  async sync(params: { userId: string }): Promise<{ succeed: boolean }> {
    return { succeed: true }
  }
}
