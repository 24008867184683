import { NewsletterEntity } from "../entities/NewsletterEntity"
import { INewsletterRepository } from "../interfaces/INewsletterRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseNewsletterRepository
  extends FirebaseUtils
  implements INewsletterRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "newsletter"

  async get(params: { userId: string }): Promise<NewsletterEntity | null> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!response.exists) return null

    const data = response.data()

    return data as NewsletterEntity
  }

  async update(params: {
    userId: string
    newsletter: NewsletterEntity
  }): Promise<NewsletterEntity> {
    const firestore = this.firebase.database()

    await firestore
      .collection(this.collection)
      .doc(params.userId)
      .set(params.newsletter)

    return params.newsletter
  }
}
