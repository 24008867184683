// import Ticket from "../assets/marketplace/ticket.svg"
import {
  MarketplaceCoinEntity,
  MarketplaceEntity,
} from "../entities/MarketplaceEntity"

const validateData = (items: MarketplaceEntity[]) => {
  const length = items.length
  const uniqId = []

  items.forEach((item) => {
    if (uniqId.includes(item.itemId))
      throw new Error(
        `${item.itemId} is already set for another marketplace item`
      )

    uniqId.push(item.itemId)
  })

  if (length !== uniqId.length)
    throw new Error(
      "items are less or greater than uniqIds " +
        JSON.stringify({ length, uniqId })
    )

  return items
}

export const DAILY_SMALL_CHEST_ID = "daily-small-chest"
export const HEARTS_ONE_DAY_POWER_UP = "heart-power-up"
export const SUBSCRIPTION_ITEM_ID = "subscription"

// @TODO
export const SUBSCRIPTION_PRODUCT_ID =
  process.env.NODE_ENV === "development"
    ? "f5b1f7dc-8f5f-4dd3-8053-c26f53a4670e"
    : ""

export const marketplaceItems: MarketplaceEntity[] = validateData([
  {
    stock: 1,
    price: 0,
    itemId: DAILY_SMALL_CHEST_ID,
    title: "marketplace/items/daily-small-chest/title",
    description: "marketplace/items/daily-small-chest/description",
    image: "/chest/silver/closed.svg",
    type: "recurrent",
    recurrence: "daily",
  },
  // {
  //   stock: 1,
  //   price: 10,
  //   itemId: "lottery-ticket",
  //   title: "Lotterie",
  //   description:
  //     "Laissez le hasard vous permettre de gagnez un coffre ultime ! Vous avez 1 chance sur 10 de gagner ce coffre.",
  //   image: Ticket,
  //   type: "recurrent",
  //   recurrence: "daily",
  // },

  {
    stock: 1,
    price: 10,
    itemId: HEARTS_ONE_DAY_POWER_UP,
    title: "marketplace/items/heart-one-day/title",
    description: "marketplace/items/heart-one-day/description",
    image: "/marketplace/hearts.svg",
    type: "recurrent",
    recurrence: "daily",
  },

  // LEOPARD ASTRONAUT
  ...Array.from({ length: 7 }).map(
    (e, index): MarketplaceEntity => ({
      stock: 1,
      price: 200,
      itemId: `marketplace/leopard-astronaut-${index + 1}.png`,
      title: "marketplace/items/leopard-astronaut-1/title",
      description: "marketplace/items/leopard-astronaut/description",
      image: `/avatars/marketplace/leopard-astronaut-${index + 1}.png`,
      group: "leopard-astronaut",
      type: "avatar",
    })
  ),

  // MAJESTIC DOGS
  ...Array.from({ length: 12 }).map(
    (e, index): MarketplaceEntity => ({
      stock: 1,
      price: 200,
      itemId: `marketplace/majestic-dog-${index + 1}.png`,
      title: "marketplace/items/majestic-dog/title",
      description: "marketplace/items/majestic-dog/description",
      image: `/avatars/marketplace/majestic-dog-${index + 1}.png`,
      group: "majestic-dog",
      type: "avatar",
    })
  ),

  // Warriors
  ...Array.from({ length: 12 }).map(
    (e, index): MarketplaceEntity => ({
      stock: 1,
      price: 200,
      itemId: `marketplace/warriors-${index + 1}.jpg`,
      title: "marketplace/items/warriors/title",
      description: "marketplace/items/warriors/description",
      image: `/avatars/marketplace/warriors-${index + 1}.jpg`,
      group: "warriors",
      type: "avatar",
    })
  ),
])

export const marketplaceAvatarItems: MarketplaceEntity[] =
  marketplaceItems.filter(({ type }) => type === "avatar")

export const marketplaceCoinsItems: MarketplaceCoinEntity[] = [
  {
    image: "/marketplace/coins/small.svg",
    price: 4.99,
    amount: 500,
    id: "40120",
  },
  {
    image: "/marketplace/coins/medium.svg",
    price: 9.99,
    amount: 1200,
    id: "40231",
  },
  {
    image: "/marketplace/coins/big.svg",
    price: 19.99,
    amount: 3500,
    id: "40232",
  },
]

export const marketplaceLeopardAstronautAvatarItems: MarketplaceEntity[] =
  marketplaceItems.filter(
    ({ type, group }) => type === "avatar" && group === "leopard-astronaut"
  )

export const marketplaceMajesticDogsAvatarItems: MarketplaceEntity[] =
  marketplaceItems.filter(
    ({ type, group }) => type === "avatar" && group === "majestic-dog"
  )

export const marketplaceWarriorsAvatarItems: MarketplaceEntity[] =
  marketplaceItems.filter(
    ({ type, group }) => type === "avatar" && group === "warriors"
  )

export const marketplaceRecurrentItems: MarketplaceEntity[] =
  marketplaceItems.filter(({ type }) => type === "recurrent")
export const marketplacePowerUpItems: MarketplaceEntity[] =
  marketplaceItems.filter(({ type }) => type === "power-up")
