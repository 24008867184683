import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import dayjs from "dayjs"

export const DailyFetching = (): types.DailyActionTypes => ({
  type: types.DailyFetching,
})

export const DailyFetchEnd = (): types.DailyActionTypes => ({
  type: types.DailyFetchEnd,
})

export const NextMonth = (): types.DailyActionTypes => ({
  type: types.NextMonth,
})

export const $NextMonth =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(NextMonth())

    const { daily } = getState()

    dispatcher(
      $FetchGamesOfMonth({
        date: daily.currentMonth,
        level: daily.level,
      })
    )
  }

export const PreviousMonth = (): types.DailyActionTypes => ({
  type: types.PreviousMonth,
})

export const $PreviousMonth =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(PreviousMonth())

    const { daily } = getState()

    dispatcher(
      $FetchGamesOfMonth({
        date: daily.currentMonth,
        level: daily.level,
      })
    )
  }

export const StoreUsersRanking = (
  payload: types.StoreUsersRankingAction["payload"]
): types.DailyActionTypes => ({
  type: types.StoreUsersRanking,
  payload,
})

export const StoreDaysAchieved = (
  payload: types.StoreDaysAchievedAction["payload"]
): types.DailyActionTypes => ({
  type: types.StoreDaysAchieved,
  payload,
})

export const StoreUserRanking = (
  payload: types.StoreUserRankingAction["payload"]
): types.DailyActionTypes => ({
  type: types.StoreUserRanking,
  payload,
})

export const StoreDailyPlayedStatus = (
  payload: types.StoreDailyPlayedStatusAction["payload"]
): types.DailyActionTypes => ({
  type: types.StoreDailyPlayedStatus,
  payload,
})

export const SelectDay = (
  payload: types.SelectDayAction["payload"]
): types.DailyActionTypes => ({
  type: types.SelectDay,
  payload,
})

export const $FetchUserSelectDay =
  (props: {
    userId: string
    dailyId: string
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState()

    const userSelfRanking = await di.DailyRepository.findSelfRanking({
      dailyId: props.dailyId,
      userId: props.userId,
    })

    if (!userSelfRanking) return dispatcher(StoreUserRanking(null))

    const getUser = await di.PointsRepository.getUser({
      userId: props.userId,
    })

    dispatcher(
      StoreUserRanking({
        ...getUser,
        duration: userSelfRanking.duration,
      })
    )
  }

export const $SelectDay =
  (
    payload: types.SelectDayAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, daily, auth } = getState()

    dispatcher(SelectDay(payload))

    dispatcher(DailyFetching())

    const level = payload.level || daily.level

    const dailyId = dayjs(payload.date).format("YYYY-MM-DD") + ":" + level

    const ranking = await di.DailyRepository.findRanking({
      daily: dailyId,
    })

    const getUsers = await Promise.all(
      ranking.map(async (rank) => {
        if (rank.userId === "anonymous")
          return {
            avatar: "other/anonymous.jpg",
            points: 100,
            username: "anonymous",
            userId: "anonymous",
            duration: rank.duration,
          }

        const user = await di.PointsRepository.getUser({ userId: rank.userId })

        return {
          ...user,
          duration: rank.duration,
        }
      })
    )

    if (!auth.user) {
      const response = await di.AuthRepository.isAuthenticated()

      if (response.authenticated) {
        await dispatcher(
          $FetchUserSelectDay({ dailyId, userId: response.user.id })
        )
      }
    } else {
      await dispatcher($FetchUserSelectDay({ dailyId, userId: auth.user.id }))
    }

    dispatcher(StoreUsersRanking(getUsers))
    dispatcher(DailyFetchEnd())
  }

export const $FetchGamesOfMonth =
  (params: {
    date: Date
    level: string
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, daily, auth } = getState()

    const response = await di.AuthRepository.isAuthenticated()
    const date = dayjs(params.date)

    if (response.authenticated) {
      const stats = await di.DailyRepository.getGamesFromMonthOfUser({
        userId: response.user.id,
        level: params.level,
        interval: {
          start: date.date(0).toDate(),
          end: date.date(date.daysInMonth()).toDate(),
        },
      })

      dispatcher(
        StoreDaysAchieved({
          daysAchieved: stats.map((value) => new Date(value.date)),
        })
      )
    }
  }

export const SelectLevel = (
  payload: types.SelectLevelAction["payload"]
): types.DailyActionTypes => ({
  type: types.SelectLevel,
  payload,
})

export const $FetchOnMount =
  (props: { level: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, daily, auth } = getState()
    dispatcher(SelectLevel({ level: props.level }))
    dispatcher($SelectDay({ date: daily.selected, level: props.level }))
    dispatcher($FetchGamesOfMonth({ date: daily.selected, level: props.level }))
  }
