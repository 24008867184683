import { DailyEntity, UserDailyEntity } from "../entities/DailyEntity"
import { IDailyRepository } from "../interfaces/IDailyRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseDailyRepository
  extends FirebaseUtils
  implements IDailyRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "daily"

  async getUserId(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const firebase = this.firebase.getInstance()
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) return resolve(user.uid)

        return resolve(null)
      })
    })
  }

  async findRanking(params: { daily: string }): Promise<DailyEntity[]> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.daily)
      .collection("ranking")
      .orderBy("duration", "asc")
      .limit(3)
      .get()

    return this.mapQuerySnapshot<DailyEntity>(response)
  }

  async findSelfRanking(params: {
    dailyId: string
    userId: string
  }): Promise<DailyEntity | null> {
    const firestore = this.firebase.database()
    const userId = await this.getUserId()

    if (!userId) return null

    const response = await firestore
      .collection(this.collection)
      .doc(params.dailyId.toString())
      .collection("ranking")
      .doc(userId)
      .get()

    if (!response.exists) return null

    const data = response.data()

    return data as DailyEntity
  }

  async storeDailyResult(params: DailyEntity): Promise<DailyEntity> {
    const firestore = this.firebase.database()
    const doc = firestore
      .collection(this.collection)
      .doc(params.dailyId)
      .collection("ranking")
      .doc(params.userId)

    const results = await doc.get()

    if (!results.exists) await doc.set(params)
    else if (results.data().duration > params.duration) {
      await doc.set(params)
    }

    if (params.userId !== "anonymous")
      this.storeDailyOnUserStats({
        dailyId: params.dailyId,
        level: params.level,
        userId: params.userId,
        isBest: false,
        date: new Date(params.dailyId.split(":")[0]),
      })

    return params
  }

  private async storeDailyOnUserStats(
    params: UserDailyEntity
  ): Promise<UserDailyEntity> {
    const firestore = this.firebase.database()

    await firestore
      .collection("users")
      .doc(params.userId)
      .collection(`dailies:${params.level}`)
      .doc(params.dailyId)
      .set(params)

    return params
  }

  async fetch(params: { userId: string }): Promise<{ points: number }> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!response.exists) return { points: 0 }

    const data = response.data()

    return {
      points: data?.points || 0,
    }
  }

  async getGamesFromMonthOfUser(params: {
    userId: string
    interval: { start: Date; end: Date }
    level: string
  }): Promise<UserDailyEntity[]> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection("users")
      .doc(params.userId)
      .collection(`dailies:${params.level}`)
      .where("date", ">=", params.interval.start)
      .where("date", "<=", params.interval.end)
      .get()

    return this.mapQuerySnapshot<UserDailyEntity>(response).map((daily) => ({
      ...daily,
      // @ts-ignore
      date: daily.date.toDate(),
    }))
  }

  async store(params: DailyEntity): Promise<DailyEntity> {
    const firestore = this.firebase.database()
    const doc = firestore
      .collection(this.collection)
      .doc(params.userId)
      .collection("ranking")
      .doc(params.userId)

    await doc.set(params)

    return params
  }
}
