import { AchievementWithRewardEntity } from "../entities/AchievementEntity"

export const baseAchievements: AchievementWithRewardEntity[] = [
  {
    id: "stars__easy__1",
    parent: "stars__easy",
    value: 0,
    goal: 1,
    reward: "stars/easy/1.svg",
  },
  {
    id: "stars__easy__2",
    parent: "stars__easy",
    value: 0,
    goal: 10,
    reward: "stars/easy/2.svg",
  },
  {
    id: "stars__easy__3",
    parent: "stars__easy",
    value: 0,
    goal: 100,
    reward: "stars/easy/3.svg",
  },
  {
    id: "stars__easy__4",
    parent: "stars__easy",
    value: 0,
    goal: 1000,
    reward: "stars/easy/4.svg",
  },
  {
    id: "stars__medium__1",
    parent: "stars__medium",
    value: 0,
    goal: 1,
    reward: "stars/medium/1.svg",
  },
  {
    id: "stars__medium__2",
    parent: "stars__medium",
    value: 0,
    goal: 10,
    reward: "stars/medium/2.svg",
  },
  {
    id: "stars__medium__3",
    parent: "stars__medium",
    value: 0,
    goal: 100,
    reward: "stars/medium/3.svg",
  },
  {
    id: "stars__medium__4",
    parent: "stars__medium",
    value: 0,
    goal: 1000,
    reward: "stars/medium/4.svg",
  },
  {
    id: "stars__hard__1",
    parent: "stars__hard",
    value: 0,
    goal: 1,
    reward: "stars/hard/1.svg",
  },
  {
    id: "stars__hard__2",
    parent: "stars__hard",
    value: 0,
    goal: 10,
    reward: "stars/hard/2.svg",
  },
  {
    id: "stars__hard__3",
    parent: "stars__hard",
    value: 0,
    goal: 100,
    reward: "stars/hard/3.svg",
  },
  {
    id: "stars__hard__4",
    parent: "stars__hard",
    value: 0,
    goal: 1000,
    reward: "stars/hard/4.svg",
  },
  {
    id: "stars__expert__1",
    parent: "stars__expert",
    value: 0,
    goal: 1,
    reward: "stars/expert/1.svg",
  },
  {
    id: "stars__expert__2",
    parent: "stars__expert",
    value: 0,
    goal: 10,
    reward: "stars/expert/2.svg",
  },
  {
    id: "stars__expert__3",
    parent: "stars__expert",
    value: 0,
    goal: 100,
    reward: "stars/expert/3.svg",
  },
  {
    id: "stars__expert__4",
    parent: "stars__expert",
    value: 0,
    goal: 1000,
    reward: "stars/expert/4.svg",
  },
  {
    id: "stars__evil__1",
    parent: "stars__evil",
    value: 0,
    goal: 1,
    reward: "stars/evil/1.svg",
  },
  {
    id: "stars__evil__2",
    parent: "stars__evil",
    value: 0,
    goal: 10,
    reward: "stars/evil/2.svg",
  },
  {
    id: "stars__evil__3",
    parent: "stars__evil",
    value: 0,
    goal: 100,
    reward: "stars/evil/3.svg",
  },
  {
    id: "stars__evil__4",
    parent: "stars__evil",
    value: 0,
    goal: 1000,
    reward: "stars/evil/4.svg",
  },
  {
    id: "sponsorship__1",
    parent: "sponsorship",
    value: 0,
    goal: 1,
    reward: "sponsorship/1.svg",
  },
  {
    id: "sponsorship__2",
    parent: "sponsorship",
    value: 0,
    goal: 3,
    reward: "sponsorship/2.svg",
  },
  {
    id: "sponsorship__3",
    parent: "sponsorship",
    value: 0,
    goal: 10,
    reward: "sponsorship/3.svg",
  },
  {
    id: "level__10",
    parent: "level",
    value: 0,
    goal: 10,
    reward: "level/10.svg",
  },
  {
    id: "level__20",
    parent: "level",
    value: 0,
    goal: 20,
    reward: "level/20.svg",
  },
  {
    id: "level__30",
    parent: "level",
    value: 0,
    goal: 30,
    reward: "level/30.svg",
  },
  {
    id: "level__40",
    parent: "level",
    value: 0,
    goal: 40,
    reward: "level/40.svg",
  },
  {
    id: "level__50",
    parent: "level",
    value: 0,
    goal: 50,
    reward: "level/50.svg",
  },
  {
    id: "level__60",
    parent: "level",
    value: 0,
    goal: 60,
    reward: "level/60.svg",
  },
  {
    id: "level__70",
    parent: "level",
    value: 0,
    goal: 70,
    reward: "level/70.svg",
  },
  {
    id: "level__80",
    parent: "level",
    value: 0,
    goal: 80,
    reward: "level/80.svg",
  },
  {
    id: "level__90",
    parent: "level",
    value: 0,
    goal: 90,
    reward: "level/90.svg",
  },
  {
    id: "level__100",
    parent: "level",
    value: 0,
    goal: 100,
    reward: "level/100.svg",
  },
]
