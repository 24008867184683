import { ThunkAction } from "redux-thunk"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const storeState = (
  payload: types.storeStateAction["payload"]
): types.PricingActionTypes => ({
  type: types.storeState,
  payload,
})

export const $fetchState =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { auth, di } = getState()

    if (!auth.user) return false

    const state = await di.PaymentService.getSubscriptionState({
      email: auth.user.email,
    })

    dispatcher(storeState({ state: state.status }))
  }

export const $unsubscribe =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { auth, di } = getState()

    if (!auth.user) return false

    await di.PaymentService.unsubscribe({
      email: auth.user.email,
    })

    dispatcher(storeState({ state: "cancelled" }))
    dispatcher(
      actions.notifications.create({
        message: "pricing/notification/unsubscribe",
        type: "success",
        timeout: 10000,
      })
    )
  }
