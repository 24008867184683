import React, { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { navigate } from "gatsby"
import { SUBSCRIPTION_MODAL_HASH } from "../../utils/drawerUtils"
import { connector, ContainerProps } from "./containers/PremiumModal.containers"
import { XMarkIcon } from "@heroicons/react/24/solid"

export type Props = {
  onBuy: () => void
}

export const Wrapper: React.FC<Props> = (props) => {
  const location = useLocation()

  const isOpen = location.href?.includes(SUBSCRIPTION_MODAL_HASH) || false

  const onClose = () => navigate(-1)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className={`fixed inset-0 z-40 overflow-y-auto`}>
        <div className="min-h-screen sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-75"
              onClick={onClose}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="max-w-xl overflow-hidden h-screen md:py-2 mx-auto">
              <div className="bg-gradient-to-b overflow-hidden relative flex flex-col h-full from-violet-900 to-slate-900 md:rounded-lg shadow-xl">
                <div className="h-full overflow-auto no-scroll-bar relative p-8 px-4 md:p-8">
                  <div
                    className="absolute top-0 right-0 p-4 z-10 bg-white bg-opacity-0 rounded cursor-pointer mt-1 hover:bg-opacity-5"
                    onClick={onClose}
                  >
                    <XMarkIcon className="w-6 h-6 text-white" />
                  </div>

                  <div className="rounded flex-shrink-0 relative">
                    <img
                      width={"96px"}
                      height={"96px"}
                      className="w-24 h-24 mx-auto rounded"
                      src={"/chest/ultimate/open.svg"}
                    />
                  </div>
                  <div className="mt-8">
                    <p className="font-display text-center text-white font-semibold text-xl">
                      <FormattedMessage id="premium/modal/title" />
                    </p>
                    <p className="text-center text-slate-50">
                      <FormattedMessage
                        id="premium/modal/description"
                        values={{
                          p: (
                            <span>
                              <span className="line-through opacity-50">
                                9,99€
                              </span>
                              <span className="pl-1 font-bold">4,99€*</span>
                            </span>
                          ),
                        }}
                      />
                    </p>
                    <p className="text-center mt-4 text-xs text-slate-50 opacity-50">
                      <FormattedMessage id="premium/modal/taxes" />
                    </p>
                  </div>
                  <div className="w-full p-4 mt-8 rounded-xl bg-white bg-opacity-5">
                    <div className="flex">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          ❤️
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/modal/features/hearts/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/modal/features/hearts/description" />
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-4">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          ✅
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/modal/features/ads/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/modal/features/ads/description" />
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-4">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          🎁
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/modal/features/emeralds/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/modal/features/emeralds/description" />
                        </div>
                      </div>
                    </div>

                    <div className="flex mt-4">
                      <div>
                        <div className="rounded-full w-10 h-10 text-2xl flex items-center justify-center border-violet-500">
                          🕰
                        </div>
                      </div>
                      <div className="pl-4">
                        <div className="text-white font-bold font-display text-lg">
                          <FormattedMessage id="premium/modal/features/history/title" />
                        </div>
                        <div className="text-slate-50">
                          <FormattedMessage id="premium/modal/features/history/description" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 text-6xl text-center">🤝</div>
                  <div className="mt-4">
                    <p className="font-display text-center text-white font-semibold text-xl">
                      <FormattedMessage id="premium/modal/support/title" />
                    </p>
                    <p className="text-center text-slate-50">
                      <FormattedMessage id="premium/modal/support/description" />
                    </p>
                  </div>
                </div>

                <div className="mt-auto p-4 flex-shrink-0 border-t-2 border-violet-900">
                  <div className="mx-auto">
                    <div className="flex items-center justify-center">
                      <button
                        onClick={props.onBuy}
                        className="bg-white outline-none rounded-xl transition-all ease-in-out duration-300 font-display font-semibold p-4 shadow-btn-2 text-violet-500 shadow-violet-500 border-2 border-violet-500"
                      >
                        <FormattedMessage
                          id="premium/modal/pay"
                          values={{
                            p: (
                              <span>
                                <span className="line-through opacity-50">
                                  9,99€
                                </span>
                                <span className="pl-1">4,99€</span>
                              </span>
                            ),
                          }}
                        />
                      </button>
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={onClose}
                        className="transition-all px-4 ease-in-out duration-300 font-display font-semibold py-2 mt-2 text-white"
                      >
                        <FormattedMessage id="premium/modal/cancel" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const PremiumModal = connector(Container)
