import React, { ReactNode, useContext } from "react"
import { Link } from "../../Link/Link"

export const A: React.FC<{ children: ReactNode; href: string }> = ({
  children,
  href,
}) => {


  const rel = href.includes("$obfuscated")
    ? "obfuscated"
    : href.includes("$nofollow")
    ? "nofollow"
    : "follow"

  return (
    <Link
      url={href.replace("$obfuscated", "").replace("$nofollow", "")}
      rel={rel}
      className={`font-medium underline`}
    >
      {children}
    </Link>
  )
}
