import { AchievementEntity } from "../entities/AchievementEntity"
import { IAchievementsRepository } from "../interfaces/IAchievementsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseAchievementsRepository
  extends FirebaseUtils
  implements IAchievementsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "achievements"

  async fetchAll(params: { userId: string }): Promise<AchievementEntity[]> {
    const firestore = this.firebase.database()

    const response = await firestore
      .collection(this.collection)
      .doc(params.userId)
      .get()

    if (!response.exists) return []

    const data = response.data() as { [x: string]: AchievementEntity }

    return Object.entries(data).map(([id, achievement]) => ({
      ...achievement,
      id,
    }))
  }

  async storeMany(params: {
    userId: string
    achievements: { [x: string]: AchievementEntity }
  }): Promise<{ success: boolean }> {
    const firestore = this.firebase.database()
    const doc = firestore.collection(this.collection).doc(params.userId)

    try {
      const data = await doc.get()
      if (data.exists) {
        await doc.update(params.achievements)
      } else {
        await doc.set(params.achievements)
      }
    } catch (e) {}

    return { success: true }
  }
}
