import { SubscriptionState } from "../../entities/SubscriptionEntity"

export const storeState = "storeState"
export interface storeStateAction {
  type: typeof storeState
  payload: {
    state: SubscriptionState
  }
}

export type PricingActionTypes = storeStateAction
