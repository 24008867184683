import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { AchievementEntity } from "../../entities/AchievementEntity"

export const fetching = (): types.AchievementsActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.AchievementsActionTypes => ({
  type: types.FetchEnd,
})

export const store = (
  payload: types.StoreAction["payload"]
): types.AchievementsActionTypes => ({
  type: types.Store,
  payload,
})

export const clearAchieved = (): types.AchievementsActionTypes => ({
  type: types.clearAchieved,
})

export const incrementAll = (
  payload: types.incrementAllAction["payload"]
): types.AchievementsActionTypes => ({
  type: types.incrementAll,
  payload,
})

export const setToAll = (
  payload: types.setToAllAction["payload"]
): types.AchievementsActionTypes => ({
  type: types.setToAll,
  payload,
})

export const $storeOnRepository =
  (payload: { parent: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, achievements, auth } = getState()

    if (!auth.authenticated || !auth.user?.id) return

    const achievementsToStore: {
      [x: string]: AchievementEntity
    } = achievements.achievements.reduce((accumulator, achievement) => {
      if (achievement.parent === payload.parent) {
        accumulator[achievement.parent] = {
          parent: achievement.parent,
          value: achievement.value,
        }
      }

      return accumulator
    }, {})

    await di.AchievementsRepository.storeMany({
      userId: auth.user.id,
      achievements: achievementsToStore,
    })
  }

export const $setToAll =
  (
    payload: types.setToAllAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(setToAll(payload))
    await dispatcher($storeOnRepository(payload))
  }

export const $incrementAll =
  (
    payload: types.incrementAllAction["payload"]
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(incrementAll(payload))
    await dispatcher($storeOnRepository(payload))
  }

export const $fetchAll =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (!auth.authenticated || !auth.user?.id) return false

    dispatcher(fetching())

    const response = await di.AchievementsRepository.fetchAll({
      userId: auth.user?.id,
    })

    dispatcher(store({ achievements: response }))

    dispatcher(fetchEnd())
  }
