import { StatEntity } from "../../entities/StatEntity"
import { IStatsRepositoryPeriod } from "../../interfaces/IStatsRepository"

export const Fetching = "REDUX_STATS_FETCHING"
export interface FetchingAction {
  type: typeof Fetching
}

export const FetchEnd = "REDUX_STATS_FETCH_END"
export interface FetchEndAction {
  type: typeof FetchEnd
}

export const Store = "REDUX_STATS_STORE"
export interface StoreAction {
  type: typeof Store
  payload: { stats: StatEntity[] }
}

export const ChangePeriod = "REDUX_STATS_CHANGE_PERIOD"
export interface ChangePeriodAction {
  type: typeof ChangePeriod
  payload: { period: IStatsRepositoryPeriod }
}

export type StatsActionTypes =
  | StoreAction
  | FetchEndAction
  | FetchingAction
  | ChangePeriodAction
