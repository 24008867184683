import {
  UserEntity,
  UserWithOptionalProfileEntity,
} from "../../../entities/UserEntity"

export const fetching = "REDUX_AUTH_FETCHING"
export interface fetchingAction {
  type: typeof fetching
}

export const fetchEnd = "REDUX_AUTH_FETCH_END"
export interface fetchEndAction {
  type: typeof fetchEnd
}

export const open = "REDUX_AUTH_OPEN"

export interface openAction {
  type: typeof open
}

export const close = "REDUX_AUTH_CLOSE"

export interface closeAction {
  type: typeof close
}

export const edit = "REDUX_AUTH_EDIT"
export interface editAction {
  type: typeof edit
  payload: { user: UserWithOptionalProfileEntity }
}

export const authenticate = "REDUX_AUTH_AUTHENTICATE"
export interface authenticateAction {
  type: typeof authenticate
  payload: { user: UserEntity }
}

export const forgot = "REDUX_AUTH_FORGOT"
export interface forgotAction {
  type: typeof forgot
}

export const logout = "REDUX_AUTH_LOGOUT"
export interface logoutAction {
  type: typeof logout
}

export const FlowsResetType = "REDUX_AUTH_FLOW_RESET"
export interface FlowsResetAction {
  type: typeof FlowsResetType
}

export type AuthGlobalActionTypes =
  | FlowsResetAction
  | logoutAction
  | logoutAction
  | forgotAction
  | authenticateAction
  | editAction
  | closeAction
  | openAction
  | fetchingAction
  | fetchEndAction
