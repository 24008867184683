import dayjs from "dayjs"
import { ThunkAction } from "redux-thunk"
import { SUBSCRIPTION_ITEM_ID } from "../../database/marketplace-items"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const $init =
  (): ThunkAction<any, RootState, any, any> => async (dispatch, getState) => {
    dispatch(actions.darkMode.$init())
    dispatch(actions.auth.$isAuthenticated()).then(() => {
      dispatch(actions.points.$fetch())
      dispatch(actions.achievements.$fetchAll())
      dispatch(actions.points.$fetchLeaderboard())
      dispatch(actions.coins.$fetch())
      dispatch(actions.chests.$fetch())
      dispatch(actions.marketplace.$fetch())
      dispatch($initRefresh())
      dispatch($shouldShowAds())
      dispatch(actions.newsletter.$fetchNewsletter())
    })
  }

export const storeAds = (
  payload: types.storeAdsAction["payload"]
): types.GlobalActionTypes => ({
  type: types.storeAds,
  payload,
})

export const storeIsPremium = (
  payload: types.storeIsPremiumAction["payload"]
): types.GlobalActionTypes => ({
  type: types.storeIsPremium,
  payload,
})

export const $shouldShowAds =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { auth } = getState()

    if (!auth.user?.id) return dispatcher(storeAds({ showAds: true }))

    await dispatcher(actions.pricing.$fetchState())

    const { pricing } = getState()
    if (pricing.isPremium) return dispatcher(storeAds({ showAds: false }))

    return dispatcher(storeAds({ showAds: true }))
  }

export const $refreshAds =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { global } = getState()

    // if (global.ads.show) {
    //   dispatcher(storeAds({ showAds: false }))

    //   setTimeout(() => {
    //     dispatcher(storeAds({ showAds: true }))
    //   }, 100)
    // }
  }

export const $initRefresh =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    setInterval(() => {
      dispatcher($shouldRefresh())
    }, 1000 * 60 * 10)
  }

export const $shouldRefresh =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(actions.marketplace.refresh())
  }
