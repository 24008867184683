import slugify from "slugify"

export const slugifyForAnchors = (title: string) => {
  const slugged = slugify(title, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
  })

  if (slugged === "") {
    return title.trim().replace(" ", "-")
  }

  return slugged
}
