const stats = require("../urls/stats.json")
const leaderboard = require("../urls/leaderboard.json")
const lessons = require("../urls/lessons.json")
const levels = require("../urls/levels.json")
const daily = require("../urls/daily.json")
const share = require("../urls/share.json")
const blog = require("../urls/blog.json")
const signin = require("../urls/signin.json")
const print = require("../urls/print.json")

const normalizeUrl = (lang, url) => {
  if (lang === "en") return `${url}`
  return `/${lang}${url}`
}

exports.normalizeUrl = normalizeUrl

exports.getPremiumUrl = (lang) => {
  if (lang === "en") return `/pricing/`

  return `/${lang}/pricing/`
}

exports.getTermsOfServiceUrl = (lang) =>
  normalizeUrl(lang, "/terms-of-service/")

exports.getRefundPolicyUrl = (lang) => normalizeUrl(lang, "/refund-policy/")

const getSigninUrl = (lang) => {
  if (lang === "en") return `/${signin[lang].signin}/`

  return `/${lang}/${signin[lang].signin}/`
}

exports.getSigninUrl = getSigninUrl

exports.getSigninLinkValidationUrl = (lang) => {
  return `${getSigninUrl(lang)}${signin[lang].validation}/`
}

exports.getLessonsUrl = (lang) => {
  if (lang === "en") return `/${leaderboard[lang]}/`

  return `/${lang}/${leaderboard[lang]}/`
}

exports.getLeaderboardUrl = (lang) => {
  if (lang === "en") return `/${leaderboard[lang]}/`

  return `/${lang}/${leaderboard[lang]}/`
}

exports.getBlogUrl = (lang) => {
  if (lang === "en") return `/${blog[lang]}/`

  return `/${lang}/${blog[lang]}/`
}

exports.getBlogArticleUrl = (lang, url) => {
  return normalizeUrl(lang, `/blog${url}`)
}

exports.navigateToStats = (lang) => {
  if (lang === "en") return `/${stats[lang]}/`

  return `/${lang}/${stats[lang]}/`
}

exports.getShareUrl = (lang) => {
  if (lang === "en") return `/${share[lang]}/`

  return `/${lang}/${share[lang]}/`
}

exports.oldNavigateToLessons = (lang) => {
  return lang === "en"
    ? `/${lessons[lang].index}/`
    : `/${lang}/${lessons[lang].index}/`
}

const navigateToLessons = (lang) => {
  return lang === "en" ? `/learn/` : `/${lang}/learn/`
}

exports.navigateToLessons = navigateToLessons

exports.getLessonUrl = ({ lang, level, url }) => {
  return `${navigateToLessons(lang)}${url}/`
}

exports.getCourseUrl = ({ lang, level }) => {
  return lang === "en"
    ? `/${lessons[lang].index}/${levels[lang][level]}/`
    : `/${lang}/${lessons[lang].index}/${levels[lang][level]}/`
}

exports.getCoursesUrl = ({ lang }) => {
  return lang === "en"
    ? `/${lessons[lang].index}/`
    : `/${lang}/${lessons[lang].index}/`
}

exports.navigateToHome = (lang) => {
  return lang === "en" ? "/" : `/${lang}/`
}

const getLevelUrl = (lang, level) => {
  const url =
    lang === "en"
      ? `/${levels[lang][level]}/`
      : `/${lang}/${levels[lang][level]}/`

  return url
}

exports.getLevelUrl = getLevelUrl

exports.getDailyUrl = (lang, level) => {
  const url =
    lang === "en"
      ? `/${daily[lang]}/${levels[lang][level]}/`
      : `/${lang}/${daily[lang]}/${levels[lang][level]}/`

  return url
}

exports.getPrintUrl = (lang, level) => {
  return getLevelUrl(lang, level) + print[lang] + "/"
}

exports.getMarkeplaceUrl = (lang) => {
  if (lang === "en") return `/marketplace/`

  return `/${lang}/marketplace/`
}

exports.getProfileUrl = (lang) => {
  if (lang === "en") return `/profile/`

  return `/${lang}/profile/`
}

exports.getAchievementsUrl = (lang) => {
  if (lang === "en") return `/achievements/`

  return `/${lang}/achievements/`
}
