import { ChestEntity } from "../entities/ChestEntity"
import { IChestRepository } from "../interfaces/IChestRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseChestRepository
  extends FirebaseUtils
  implements IChestRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "chests"

  async getAll(params: { userId: string }): Promise<ChestEntity[]> {
    const firestore = this.firebase.database()
    const collection = firestore
      .collection(this.collection)
      .where("userId", "==", params.userId)

    const response = await collection.get()

    if (response.empty) return []

    return this.mapQuerySnapshot<ChestEntity>(response.docs).map((chest) => ({
      ...chest,
    }))
  }

  async remove(params: { userId: string; chestId: string }): Promise<void> {
    const firestore = this.firebase.database()

    await firestore.collection(this.collection).doc(params.chestId).delete()
  }

  async create(params: { chest: ChestEntity }): Promise<ChestEntity> {
    const firestore = this.firebase.database()

    const doc = firestore.collection(this.collection).doc()
    const entity = {
      ...params.chest,
      id: doc.id,
    }

    await firestore.collection("chests").doc(doc.id).set(entity)

    return entity
  }
}
