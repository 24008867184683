import { SubscriptionState } from "../entities/SubscriptionEntity"
import { IPaymentService } from "../interfaces/IPaymentService"

export class InMemoryPaymentService implements IPaymentService {
  async getSubscriptionState(params: {
    email: string
  }): Promise<{ status: SubscriptionState }> {
    return { status: "active" }
  }

  async unsubscribe(params: {
    email: string
  }): Promise<{ status: SubscriptionState }> {
    return { status: "cancelled" }
  }

  async openSubscriptionDialog(params: {
    email: string
    productId: string | number
  }): Promise<{ status: "success" | "closed" }> {
    if (params.email === "closed") return { status: "closed" }
    return { status: "success" }
  }
}
