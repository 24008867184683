import { DailyEntity } from "../../entities/DailyEntity"
import { PointsEntity } from "../../entities/PointsEntity"
import { UserEntity } from "../../entities/UserEntity"

export const DailyFetching = "DailyFetching"
export interface DailyFetchingAction {
  type: typeof DailyFetching
}

export const DailyFetchEnd = "DailyFetchEnd"
export interface DailyFetchEndAction {
  type: typeof DailyFetchEnd
}

export const SelectDay = "SelectDay"
export interface SelectDayAction {
  type: typeof SelectDay
  payload: {
    date: Date
    level?: string
  }
}

export const StoreUsersRanking = "StoreUsersRanking"
export interface StoreUsersRankingAction {
  type: typeof StoreUsersRanking
  payload: Array<PointsEntity & { duration: number }>
}

export const StoreUserRanking = "StoreUserRanking"
export interface StoreUserRankingAction {
  type: typeof StoreUserRanking
  payload: (PointsEntity & { duration: number }) | null
}

export const StoreDailyPlayedStatus = "StoreDailyPlayedStatus"
export interface StoreDailyPlayedStatusAction {
  type: typeof StoreDailyPlayedStatus
  payload: { status: boolean }
}

export const StoreDaysAchieved = "StoreDaysAchieved"
export interface StoreDaysAchievedAction {
  type: typeof StoreDaysAchieved
  payload: { daysAchieved: Date[] }
}

export const SelectLevel = "SelectLevel"
export interface SelectLevelAction {
  type: typeof SelectLevel
  payload: { level: string }
}

export const PreviousMonth = "PreviousMonth"
export interface PreviousMonthAction {
  type: typeof PreviousMonth
}

export const NextMonth = "NextMonth"
export interface NextMonthAction {
  type: typeof NextMonth
}

export type DailyActionTypes =
  | DailyFetchEndAction
  | DailyFetchingAction
  | SelectDayAction
  | PreviousMonthAction
  | NextMonthAction
  | StoreUsersRankingAction
  | StoreUserRankingAction
  | StoreDailyPlayedStatusAction
  | StoreDaysAchievedAction
  | SelectLevelAction
