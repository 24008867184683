import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

type Props = {
  src?: any
  alt: string
  className?: string
}

export const Image: React.FC<Props> = props => {
  if (!props.src?.childImageSharp) return <img {...props} />

  const image = getImage(props.src.childImageSharp)

  return (
    <GatsbyImage image={image} alt={props.alt} className={props.className} />
  )
}
