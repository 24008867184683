import * as types from "./types"
import { PlayService } from "../../services/PlayService"
import { NewsletterEntity } from "../../entities/NewsletterEntity"

interface NewsletterState {
  fetching: boolean
  newsletter: NewsletterEntity | null
}

const initialState: NewsletterState = {
  fetching: false,
  newsletter: null,
}

export function newsletterReducer(
  state = initialState,
  action: types.NewsletterActionTypes
): NewsletterState {
  if (action.type === types.storeNewsletter) {
    return {
      ...state,
      newsletter: action.payload.newsletter,
    }
  }

  return state
}
