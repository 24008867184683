import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  notifications: state.notifcations.coins,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
