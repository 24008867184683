import * as play from "./play/actions"
import * as auth from "./auth/actions"
import * as darkMode from "./dark-mode/actions"
import * as stats from "./stats/actions"
import * as di from "./di/actions"
import * as points from "./points/actions"
import * as lang from "./lang/actions"
import * as notifications from "./notifications/actions"
import * as achievements from "./achievements/actions"
import * as daily from "./daily/actions"
import * as print from "./print/actions"
import * as global from "./global/actions"
import * as coins from "./coins/actions"
import * as chests from "./chests/actions"
import * as marketplace from "./marketplace/actions"
import * as pricing from "./pricing/actions"
import * as newsletter from "./newsletter/actions"

export const actions = {
  play,
  pricing,
  marketplace,
  achievements,
  coins,
  auth,
  notifications,
  darkMode,
  stats,
  lang,
  points,
  di,
  daily,
  global,
  print,
  chests,
  newsletter,
}
