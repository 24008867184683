import { DailyEntity, UserDailyEntity } from "../entities/DailyEntity"
import { IDailyRepository } from "../interfaces/IDailyRepository"

export class InMemoryDailyRepository implements IDailyRepository {
  private daily: Map<DailyEntity["dailyId"], DailyEntity[]> = new Map()

  async findRanking(params: { daily: string }): Promise<DailyEntity[]> {
    return this.daily.get(params.daily) || []
  }

  __store(params: DailyEntity[]) {
    params.forEach((daily) => {
      this.storeDailyResult(daily)
    })
  }

  async findSelfRanking(params: {
    dailyId: string
    userId: string
  }): Promise<DailyEntity | null> {
    const ranking = await this.findRanking({ daily: params.dailyId })
    return ranking.find((ranking) => {
      return ranking.userId === params.userId
    })
  }

  async storeDailyResult(params: DailyEntity): Promise<DailyEntity> {
    this.daily.set(params.dailyId, [
      ...(this.daily.get(params.dailyId) || []),
      params,
    ])

    return params
  }

  getGamesFromMonthOfUser(params: {
    userId: string
    interval: { start: Date; end: Date }
    level: string
  }): Promise<UserDailyEntity[]> {
    throw new Error("Method not implemented.")
  }
}
