import { ThunkAction } from "redux-thunk"
import * as types from "./types"

export const store = (
  payload: types.storeAction["payload"]
): types.NotificationsActionTypes => ({
  type: types.store,
  payload,
})

export const remove = (
  payload: types.removeAction["payload"]
): types.NotificationsActionTypes => ({
  type: types.remove,
  payload,
})

export const create =
  (
    snack: Omit<types.storeAction["payload"], "id">
  ): ThunkAction<void, any, any, any> =>
  (dispatcher) => {
    const id = Date.now()
    const timeout = snack.timeout || 3000

    dispatcher(store({ ...snack, id, timeout }))

    setTimeout(() => dispatcher(remove({ id })), timeout)
  }

export const storeCoins = (
  payload: types.storeCoinsAction["payload"]
): types.NotificationsActionTypes => ({
  type: types.storeCoins,
  payload,
})

export const removeCoin = (
  payload: types.removeCoinAction["payload"]
): types.NotificationsActionTypes => ({
  type: types.removeCoin,
  payload,
})

export const $createCoins =
  (
    snack: Omit<types.storeCoinsAction["payload"], "id">
  ): ThunkAction<void, any, any, any> =>
  (dispatcher) => {
    const id = Date.now()
    const timeout = snack.timeout || 3000

    dispatcher(storeCoins({ ...snack, id, timeout }))

    setTimeout(() => dispatcher(removeCoin({ id })), timeout)
  }
