import {
  CelEntity,
  GameEntity,
  MatrixEntity,
  RowEntity,
} from "../entities/GameEntity"

import groupBy from "ramda/src/groupBy"
import splitEvery from "ramda/src/splitEvery"
import uniq from "ramda/src/uniq"

export const VERSION = 7
export class PlayService {
  private game: GameEntity
  private selected: string | null
  private undo: MatrixEntity[]
  public version: number
  public duration: number
  public clues: number
  private errors: number
  private maxClues: number

  constructor(
    params: Omit<GameEntity, "matrix">,
    extend?: { lifePowerUp?: boolean }
  ) {
    this.game = this.create(params)
    this.selected = null
    this.undo = [this.game.matrix]
    this.version = VERSION
    this.errors = extend?.lifePowerUp ? -3 : 0
    this.duration = 0
    this.clues = 0
    this.maxClues = 3
  }

  static load(game: PlayService) {
    const play = new PlayService(game.game)
    play.game.matrix = game.game.matrix
    play.undo = game.undo
    play.errors = game.errors
    play.duration = game.duration || 0
    play.clues = game.clues || 0
    return play
  }

  tick() {
    this.duration = this.duration + 1
  }

  create(params: Omit<GameEntity, "matrix">): GameEntity {
    return {
      ...params,
      matrix: this.createMatrix(params.initial, params.solution),
    }
  }

  getGame(): GameEntity {
    return this.game
  }

  getSelected() {
    return this.selected
  }

  createMatrix(base: string, solution: string): MatrixEntity {
    const rows: Array<string> = splitEvery(9, base)

    return rows.map((row, rowIndex) =>
      row.split("").map((col, colIndex) => {
        return {
          id: `${rowIndex}:${colIndex}:${this.getSquareId(rowIndex, colIndex)}`,
          solution: this.getSolution(rowIndex, colIndex, solution),
          number: Number(col),
          default: col === "0" ? false : true,
          drafts: Array.from({ length: 9 }).map(() => null),
          highlight: null,
        }
      })
    )
  }

  getNumberErrors() {
    return this.errors
  }

  up() {
    if (!this.selected) return false

    const [row, cel] = this.selected?.split(":")

    if (row === "0") return this.selected

    const newRow = Number(row) - 1

    this.selectCel(`${newRow}:${cel}:${this.getSquareId(newRow, Number(cel))}`)
  }

  down() {
    if (!this.selected) return false

    const [row, cel] = this.selected?.split(":")

    if (row === "8") return this.selected

    const newRow = Number(row) + 1

    this.selectCel(`${newRow}:${cel}:${this.getSquareId(newRow, Number(cel))}`)
  }

  left() {
    if (!this.selected) return false

    const [row, cel] = this.selected?.split(":")

    if (cel === "0") return this.selected

    const newCel = Number(cel) - 1

    this.selectCel(
      `${row}:${newCel}:${this.getSquareId(Number(row), Number(newCel))}`
    )
  }

  right() {
    if (!this.selected) return false

    const [row, cel] = this.selected?.split(":")

    if (cel === "8") return this.selected

    const newCel = Number(cel) + 1

    this.selectCel(
      `${row}:${newCel}:${this.getSquareId(Number(row), Number(newCel))}`
    )
  }

  unSelect() {
    this.selected = null

    this.game.matrix = this.game.matrix.map((row) =>
      row.map((cel) => ({
        ...cel,
        highlight: cel.highlight === "error" ? cel.highlight : null,
      }))
    )
  }

  changeDraft(value: number) {
    this.game.matrix = this.game.matrix.map((row) =>
      row.map((cel) => ({
        ...cel,
        drafts:
          cel.id === this.selected
            ? cel.drafts.map((number, index) => {
                if (index !== value - 1) return number
                if (number) return null
                return value
              })
            : cel.drafts,
      }))
    )

    this.saveUndo()
  }

  getSquareId(rowIndex: number, colIndex: number) {
    if (rowIndex < 3) {
      if (colIndex < 3) return "0"
      if (colIndex < 6) return "1"
      return "2"
    } else if (rowIndex < 6) {
      if (colIndex < 3) return "3"
      if (colIndex < 6) return "4"
      return "5"
    } else {
      if (colIndex < 3) return "6"
      if (colIndex < 6) return "7"
      return "8"
    }
  }

  selectCel(id: string): void {
    this.selected = id

    const [row, col, square] = id.split(":")

    const selectedCel: CelEntity = this.game.matrix[row][col]

    this.game.matrix = this.game.matrix.map((row) =>
      row.map((cel) => ({
        ...cel,
        highlight:
          cel.highlight === "error"
            ? cel.highlight
            : selectedCel.number && selectedCel.number === cel.number
            ? "light-dark"
            : this.shouldHighlight(id, cel.id)
            ? "light"
            : null,
      }))
    )
  }

  recoverOneLife() {
    this.errors = this.errors - 1

    return this
  }

  shouldHighlight(selectedId: string, celId: string) {
    const selected = selectedId.split(":")
    const cel = celId.split(":")

    if (selected[0] === cel[0]) return true
    if (selected[1] === cel[1]) return true
    if (selected[2] === cel[2]) return true

    return false
  }

  getSolution(row: number, col: number, solution: string): number {
    const index = row * 9 + col
    return Number(solution[index])
  }

  showClue() {
    if (!this.selected) return
    if (this.clues === this.maxClues) return

    const [row, col, square] = this.selected.split(":")

    const cel = this.game.matrix[row][col]

    this.updateCel(this.selected, Number(cel.solution))
    this.incrementClues()
  }

  incrementClues() {
    this.clues = this.clues + 1
  }

  getStats() {
    return {
      level: this.game.difficulty,
      errors: Math.max(this.getNumberErrors(), 0),
      clues: this.clues,
      duration: this.duration,
    }
  }

  check() {
    const doublons = uniq([
      ...this.checkDoublonCols(),
      ...this.checkDoublonRows(),
      ...this.checkDoublonSquare(),
    ])

    this.game.matrix = this.game.matrix.map((cols) => {
      return cols.map((cel) => ({
        ...cel,
        highlight: doublons.includes(cel.id)
          ? "error"
          : cel.highlight === "error"
          ? null
          : cel.highlight,
      }))
    })

    this.selectCel(this.selected as string)
  }

  checkDoublonRows() {
    const doublons: string[] = []

    this.game.matrix.forEach((cols) => {
      doublons.push(...this.doublonOnList(cols))
    })

    return doublons
  }

  doublonOnList(cols: RowEntity) {
    const doublons: RowEntity = []

    const props = groupBy((cel) => String(cel.number), cols)
    if (props["1"]?.length >= 2) doublons.push(...props["1"])
    if (props["2"]?.length >= 2) doublons.push(...props["2"])
    if (props["3"]?.length >= 2) doublons.push(...props["3"])
    if (props["4"]?.length >= 2) doublons.push(...props["4"])
    if (props["5"]?.length >= 2) doublons.push(...props["5"])
    if (props["6"]?.length >= 2) doublons.push(...props["6"])
    if (props["7"]?.length >= 2) doublons.push(...props["7"])
    if (props["8"]?.length >= 2) doublons.push(...props["8"])
    if (props["9"]?.length >= 2) doublons.push(...props["9"])

    return doublons.map(({ id }) => id)
  }

  checkDoublonCols() {
    const rows = this.game.matrix
    const doublons: string[] = []

    for (let index = 0; index < 9; index++) {
      const fullRow: RowEntity = [
        rows[0][index],
        rows[1][index],
        rows[2][index],
        rows[3][index],
        rows[4][index],
        rows[5][index],
        rows[6][index],
        rows[7][index],
        rows[8][index],
      ]

      doublons.push(...this.doublonOnList(fullRow))
    }

    return doublons
  }

  checkDoublonSquare() {
    const squares: RowEntity[] = Array.from({
      length: 9,
    }).map(() => [])
    const doublons: string[] = []

    this.game.matrix.forEach((cols) => {
      cols.forEach((cel) => {
        const [, , square] = cel.id.split(":")
        squares[square].push(cel)
      })
    })

    squares.forEach((square) => {
      doublons.push(...this.doublonOnList(square))
    })

    return doublons
  }

  updateCel(id: string, digit: number) {
    if (!this.selected) return false

    this.game.matrix = this.game.matrix.map((row) =>
      row.map((cel) => ({
        ...cel,
        number:
          cel.id === id && !cel.default && cel.number !== cel.solution
            ? digit
            : cel.number,
      }))
    )

    this.shouldIncrementError(digit)
    this.removeDraftsEqualsCurrentCelIfCelIsGood(digit)
    this.check()
    this.saveUndo()
  }

  shouldIncrementError(digit: number) {
    if (!this.selected) return false
    if (digit === 0) return false

    const [row, col, square] = this.selected.split(":")

    const cel = this.game.matrix[row][col] as CelEntity

    if (cel.solution === digit) return true

    this.errors = this.errors + 1
  }

  removeDraftsEqualsCurrentCelIfCelIsGood(digit: number) {
    if (!this.selected) return false
    if (digit === 0) return false

    const [row, col, square] = this.selected.split(":")

    const cel = this.game.matrix[row][col] as CelEntity

    if (cel.solution !== digit) return

    const houses = this.getHouseIds()

    this.game.matrix = this.game.matrix.map((row) =>
      row.map((cel) => ({
        ...cel,
        drafts: cel.drafts.map((draft) => {
          if (draft === digit && houses.includes(cel.id)) return null
          return draft
        }),
      }))
    )
  }

  getHouseIds(): string[] {
    if (!this.selected) return []

    const [row, col, square] = this.selected.split(":")

    return this.game.matrix.flatMap((cols) => {
      return cols
        .filter((actualCol) => {
          const colId = actualCol.id.split(":")
          if (colId[0] === row) return true
          if (colId[1] === col) return true
          if (colId[2] === square) return true
          return false
        })
        .map((col) => col.id)
    })
  }

  setUndo() {
    const [, previous, ...rest] = this.undo

    if (!previous) return false

    this.undo = [previous, ...rest]
    this.game.matrix = previous
  }

  saveUndo() {
    this.undo = [this.game.matrix, ...this.undo.slice(0, 100)]
  }

  isWinner(): boolean {
    const actual = this.game.matrix.map((row) => row.map((cel) => cel.number))

    return actual.toString().split(",").join("") === this.game.solution
  }

  parse(base: MatrixEntity, actual: MatrixEntity): MatrixEntity {
    return actual.map((row, rowIndex) =>
      row.map((col, colIndex) => {
        if (col.number > 0 && base[rowIndex][colIndex].number !== col.number)
          return {
            ...col,
            error: true,
          }

        return {
          ...col,
          error: false,
        }
      })
    )
  }
}
