export const FlowsAvatarProcessSetFetching =
  "AUTH_FLOWS_AVATAR_PROCESS_FETCHING_SET"
export interface FlowsAvatarProcessSetFetchingAction {
  type: typeof FlowsAvatarProcessSetFetching
  payload: { fetching: boolean }
}

export const FlowsAvatarProcessError = "AUTH_FLOWS_AVATAR_PROCESS_ERROR_SET"
export interface FlowsAvatarProcessErrorAction {
  payload: { error: string }
  type: typeof FlowsAvatarProcessError
}

export const FlowsAvatarProcessUpdateValue =
  "AUTH_FLOWS_AVATAR_PROCESS_UPDATE_VALUE"
export interface FlowsAvatarProcessUpdateValueAction {
  payload: { value: string }
  type: typeof FlowsAvatarProcessUpdateValue
}

export const FlowsAvatarProcessSetModalOpenState =
  "AUTH_FLOWS_AVATAR_PROCESS_SET_MODAL_OPEN_STATE"
export interface FlowsAvatarProcessSetModalOpenStateAction {
  payload: { isOpen: boolean }
  type: typeof FlowsAvatarProcessSetModalOpenState
}

export type AuthAvatarActionTypes =
  | FlowsAvatarProcessSetFetchingAction
  | FlowsAvatarProcessErrorAction
  | FlowsAvatarProcessUpdateValueAction
  | FlowsAvatarProcessSetModalOpenStateAction
