import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { IStatsRepositoryPeriod } from "../../interfaces/IStatsRepository"

export const fetching = (): types.StatsActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.StatsActionTypes => ({
  type: types.FetchEnd,
})

export const store = (
  payload: types.StoreAction["payload"]
): types.StatsActionTypes => ({
  type: types.Store,
  payload,
})

export const $sync =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (auth.user?.id) {
      await di.StatsRepository.sync({
        userId: auth.user?.id,
      })
    }
  }

export const $findAll =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, stats, auth, pricing } = getState()

    dispatcher(fetching())

    const data = auth.user?.id
      ? await di.StatsRepository.findAll({
          period: stats.period,
          userId: auth.user?.id,
        })
      : []

    dispatcher(store({ stats: data }))
    dispatcher(fetchEnd())
  }

export const changePeriod = (
  payload: types.ChangePeriodAction["payload"]
): types.StatsActionTypes => ({
  type: types.ChangePeriod,
  payload,
})

export const $changePeriod =
  (params: {
    period: IStatsRepositoryPeriod
  }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(changePeriod(params))
    dispatcher($findAll())
  }
