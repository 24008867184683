import {
  MarketplaceBougthItemEntity,
  MarketplaceEntity,
} from "../entities/MarketplaceEntity"
import { IMarketplaceRepository } from "../interfaces/IMarketplaceRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseMarketplaceRepository
  extends FirebaseUtils
  implements IMarketplaceRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  private collection = "marketplace"

  async getAll(params: {
    userId: string
  }): Promise<MarketplaceBougthItemEntity[]> {
    const firestore = this.firebase.database()
    const collection = firestore
      .collection(this.collection)
      .where("userId", "==", params.userId)

    const response = await collection.get()

    if (response.empty) return []

    return this.mapQuerySnapshot<MarketplaceBougthItemEntity>(
      response.docs
    ).map((item) => ({
      ...item,
      // @ts-ignore
      bougthAt: item.bougthAt.toDate(),
    }))
  }

  async getOne(params: {
    userId: string
    itemId: string
  }): Promise<MarketplaceBougthItemEntity | null> {
    const firestore = this.firebase.database()
    const collection = firestore
      .collection(this.collection)
      .where("userId", "==", params.userId)
      .where("itemId", "==", params.itemId)

    const response = await collection.get()

    if (response.empty) return null

    return (
      this.mapQuerySnapshot<MarketplaceBougthItemEntity>(response.docs).map(
        (item) => ({
          ...item,
          // @ts-ignore
          bougthAt: item.bougthAt.toDate(),
        })
      )[0] || null
    )
  }

  async store(params: {
    userId: string
    item: MarketplaceBougthItemEntity
  }): Promise<MarketplaceBougthItemEntity> {
    const firestore = this.firebase.database()
    const response = await firestore
      .collection(this.collection)
      .where("userId", "==", params.userId)
      .where("itemId", "==", params.item.itemId)
      .limit(1)
      .get()

    if (!response.empty) {
      const item = this.mapQuerySnapshot<MarketplaceBougthItemEntity>(
        response.docs
      )[0]

      await firestore
        .collection(this.collection)
        .doc(item.id)
        .update({ ...item, ...params.item })

      return { ...item, ...params.item }
    }

    const doc = firestore.collection(this.collection).doc()
    const entity = { ...params.item, id: doc.id }

    await doc.set(entity)

    return entity
  }
}
