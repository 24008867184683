import { AchievementEntity } from "../entities/AchievementEntity"
import { IAchievementsRepository } from "../interfaces/IAchievementsRepository"

export class InMemoryAchievementsRepository implements IAchievementsRepository {
  private achievements: { [x: string]: AchievementEntity } = {}

  async fetchAll(params: { userId: string }): Promise<AchievementEntity[]> {
    return Object.entries(this.achievements).map(([id, achievement]) => ({
      ...achievement,
      id,
    }))
  }

  async storeMany(params: {
    userId: string
    achievements: { [x: string]: AchievementEntity }
  }): Promise<{ success: boolean }> {
    this.achievements = {
      ...this.achievements,
      ...params.achievements,
    }

    return { success: true }
  }
}
