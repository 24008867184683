import { IModule } from "../interfaces/IModule"
import { FirebaseAuthRepository } from "../repositories/FirebaseAuthRepository"
import { LocalStoragePlayRepository } from "../repositories/LocalStoragePlayRepository"
import { Firebase } from "../services/firebase"
import { GatsbyLocationService } from "../services/GatsbyLocationService"
import { BrowserLocalStorageEmailRepository } from "../repositories/BrowserLocalStorageEmail"
import { InMemoryAnalyticsService } from "../services/InMemoryAnalyticsService"
import { FirebasePointsRepository } from "../repositories/FirebasePointsRepository"
import { FirebaseStatsRepository } from "../repositories/FirebaseStatsRepository"
import { ApiUniqueNameGeneratorService } from "../services/ApiUniqueNameGeneratorService"
import { FirebaseAchievementsRepository } from "../repositories/FirebaseAchievementsRepository"
import { FirebaseDailyRepository } from "../repositories/FirebaseDailyRepository"
import { FirebaseLeaderboardRepository } from "../repositories/FirebaseLeaderboardRepository"
import { BrowserLocalStorageRepository } from "../repositories/BrowserLocalStorage"
import { FirebaseCoinsRepository } from "../repositories/FirebaseCoinsRepository"
import { FirebaseChestRepository } from "../repositories/FirebaseChestRepository"
import { FirebaseMarketplaceRepository } from "../repositories/FirebaseMarketplaceRepository"
import { LemonSqueezyPaymentService } from "../services/LemonSqueezyPaymentService"
import { FirebaseNewsletterRepository } from "../repositories/FirebaseNewsletterRepository"

export class DevelopmentModule implements IModule {
  build() {
    const firebase = new Firebase()

    const PlayRepository = new LocalStoragePlayRepository()
    const PaymentService = new LemonSqueezyPaymentService()
    const StatsRepository = new FirebaseStatsRepository(firebase)
    const NewsletterRepository = new FirebaseNewsletterRepository(firebase)
    const LeaderboardRepository = new FirebaseLeaderboardRepository(firebase)
    const MarketplaceRepository = new FirebaseMarketplaceRepository(firebase)
    const CoinsRepository = new FirebaseCoinsRepository(firebase)
    const ChestRepository = new FirebaseChestRepository(firebase)
    const PointsRepository = new FirebasePointsRepository(firebase)
    const AuthRepository = new FirebaseAuthRepository(firebase)
    const LocationService = new GatsbyLocationService()
    const LocalStorageEmailRepository = new BrowserLocalStorageEmailRepository()
    const LocalStorageRepository = new BrowserLocalStorageRepository()
    const AnalyticsService = new InMemoryAnalyticsService()
    const DailyRepository = new FirebaseDailyRepository(firebase)
    const UniqueNameGeneratorService = new ApiUniqueNameGeneratorService()
    const AchievementsRepository = new FirebaseAchievementsRepository(firebase)

    return {
      PaymentService,
      NewsletterRepository,
      MarketplaceRepository,
      ChestRepository,
      CoinsRepository,
      LeaderboardRepository,
      AchievementsRepository,
      LocalStorageRepository,
      UniqueNameGeneratorService,
      PlayRepository,
      StatsRepository,
      PointsRepository,
      AuthRepository,
      DailyRepository,
      LocationService,
      LocalStorageEmailRepository,
      AnalyticsService,
    }
  }
}
