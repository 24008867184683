export const storeCoins = "storeCoins"
export interface storeCoinsAction {
  type: typeof storeCoins
  payload: {
    amount: number
  }
}

export const spend = "COINS_spend"
export interface spendAction {
  type: typeof spend
  payload: {
    amount: number
  }
}

export const addCoins = "COINS_addCoins"
export interface addCoinsAction {
  type: typeof addCoins
  payload: {
    amount: number
  }
}

export const setFetchingCoins = "setFetchingCoins"
export interface setFetchingCoinsAction {
  type: typeof setFetchingCoins
  payload: {
    fetching: boolean
  }
}

export type CoinsActionTypes =
  | storeCoinsAction
  | setFetchingCoinsAction
  | spendAction
  | addCoinsAction
