import * as types from "./types"
import { PlayService } from "../../services/PlayService"
import { shuffle } from "lodash"
import { GameEntity, MatrixEntity } from "../../entities/GameEntity"

interface PlayState {
  games: GameEntity[]
  matrices: Array<MatrixEntity>
}

const initialState: PlayState = {
  games: [],
  matrices: [],
}

export function printReducer(
  state = initialState,
  action: types.PrintActionTypes
): PlayState {
  if (action.type === types.StoreGames) {
    return {
      ...state,
      games: action.payload.games,
    }
  }

  if (action.type === types.SelectGame) {
    const games = shuffle(state.games).slice(0, 4)

    return {
      ...initialState,
      matrices: games.map((game) => {
        return new PlayService(game).getGame().matrix
      }),
    }
  }

  return state
}
