import firebase from "firebase/app"
import config from "../../firebase/client.json"

export class Firebase {
  constructor() {
    if (firebase.apps.length === 0) firebase.initializeApp(config)

    // try {
    //   firebase.firestore().enablePersistence()
    // } catch (e) {}
  }

  database() {
    return firebase.firestore()
  }

  getInstance() {
    return firebase
  }

  getGoogleProvider() {
    return new firebase.auth.GoogleAuthProvider()
  }

  auth() {
    return firebase.auth()
  }

  analytics() {
    return firebase.analytics()
  }
}
