import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { actions } from "../actions"
import { navigate } from "gatsby"
import { DAILY_SMALL_CHEST_ID } from "../../database/marketplace-items"

export const setFetchingChests = (
  payload: types.setFetchingChestsAction["payload"]
): types.ChestsActionTypes => ({
  type: types.setFetchingChests,
  payload,
})

export const storeChests = (
  payload: types.storeChestsAction["payload"]
): types.ChestsActionTypes => ({
  type: types.storeChests,
  payload,
})

export const openModalChest = (
  payload: types.openModalChestAction["payload"]
): types.ChestsActionTypes => ({
  type: types.openModalChest,
  payload,
})

export const $fetch =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    dispatcher(setFetchingChests({ fetching: true }))

    const response = auth.user?.id
      ? await di.ChestRepository.getAll({
          userId: auth.user.id,
        })
      : []

    dispatcher(
      storeChests({
        chests: response,
      })
    )

    dispatcher(setFetchingChests({ fetching: false }))
  }

export const $storeDailyChest =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (!auth.user.id) return false

    await di.ChestRepository.create({
      chest: {
        userId: auth.user.id,
        // Between 1 and 5
        amount: Math.max(Math.round(Math.random() * 5), 1),
        createdAt: new Date(),
        id: "",
        type: "silver",
      },
    })
    await dispatcher($fetch())

    dispatcher(openModalChest({}))
    navigate(di.LocationService.getPathname() + "#chest-modal=open")
  }

export const $openChest =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth, chests, coins, pricing } = getState()

    if (!auth.user?.id) return false

    const chest = chests.chests[0]

    if (!chest) return false

    const amount = chest.amount * (pricing.isPremium ? 2 : 1)

    di.AnalyticsService.send({
      category: "chest",
      action: "openChest",
      data: {
        type: chest.type,
        amount: amount,
      },
    })

    dispatcher(
      storeChests({
        chests: chests.chests.slice(1) || [],
      })
    )

    dispatcher(
      actions.coins.storeCoins({
        amount: amount + coins.amount,
      })
    )

    await di.ChestRepository.remove({
      userId: auth.user?.id,
      chestId: chest.id,
    })

    await di.CoinsRepository.add({
      userId: auth.user?.id,
      amount: amount,
    })
  }
