import { ILocalStorageEmailRepository } from "../interfaces/ILocalStorageEmailRepository"

export class InMemoryLocalStorageEmailRepository
  implements ILocalStorageEmailRepository
{
  private email: string | null = null

  async store(email: string) {
    this.email = email
  }

  async get() {
    return this.email
  }
}
