import { AuthenticateWithLinkReturnErrorType } from "../../../interfaces/IAuthRepository"
import { FlowsSigninEmailStateCodesTypes } from "../reducers"

export const FlowsSigninLinkValidationProcessFetchingType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_FETCHING"
export interface FlowsSigninLinkValidationProcessFetchingAction {
  type: typeof FlowsSigninLinkValidationProcessFetchingType
}

export const FlowsSigninLinkValidationProcessFetchEndType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_FETCH_END"
export interface FlowsSigninLinkValidationProcessFetchEndAction {
  type: typeof FlowsSigninLinkValidationProcessFetchEndType
}

export const FlowsSigninLinkValidationProcessErrorsSetType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_ERRORS_SET"
export interface FlowsSigninLinkValidationProcessErrorsSetAction {
  payload: { error: AuthenticateWithLinkReturnErrorType }
  type: typeof FlowsSigninLinkValidationProcessErrorsSetType
}

export const FlowsSigninLinkValidationProcessSuccessUpdateType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_SUCCEED_UPDATE"
export interface FlowsSigninLinkValidationProcessSuccessUpdateAction {
  payload: { state: boolean }
  type: typeof FlowsSigninLinkValidationProcessSuccessUpdateType
}

export const FlowsSigninLinkValidationStepsNextType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_STEPS_NEXT"
export interface FlowsSigninLinkValidationStepsNextAction {
  type: typeof FlowsSigninLinkValidationStepsNextType
}

export const FlowsSigninLinkValidationStepsPreviousType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_STEPS_PREVIOUS"
export interface FlowsSigninLinkValidationStepsPreviousAction {
  type: typeof FlowsSigninLinkValidationStepsPreviousType
}

export const FlowsSigninLinkValidationProcessSetErrorType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_PROCESS_SET_ERROR"
export interface FlowsSigninLinkValidationProcessSetErrorAction {
  type: typeof FlowsSigninLinkValidationProcessSetErrorType
  payload: {
    error: AuthenticateWithLinkReturnErrorType
  }
}

export const FlowsSigninLinkValidationFormEmailUpdateType =
  "REDUX_AUTH_FLOWS_SIGNIN_LINK_VALIDATION_FORM_EMAIL_UPDATE"
export interface FlowsSigninLinkValidationFormEmailUpdateAction {
  type: typeof FlowsSigninLinkValidationFormEmailUpdateType
  payload: { email: string }
}

export const FlowsSigninLinkValidationFormEmailCheckType =
  "REDUX_AUTH_FLOWS_SIGNIN_LINK_VALIDATION_FORM_EMAIL_CHECK"
export interface FlowsSigninLinkValidationFormEmailCheckAction {
  type: typeof FlowsSigninLinkValidationFormEmailCheckType
}

export const FlowsSigninLinkValidationFormEmailUpdateStateType =
  "REDUX_AUTH_FLOW_SIGNIN_LINK_VALIDATION_FORM_EMAIL_UPDATE_STATE"
export interface FlowsSigninLinkValidationFormEmailUpdateStateAction {
  type: typeof FlowsSigninLinkValidationFormEmailUpdateStateType
  payload: {
    state: FlowsSigninEmailStateCodesTypes
  }
}

export const FlowsSigninLinkValidationFormEmailUpdateFocusType =
  "REDUX_AUTH_FLOW_SIGNIN_LINK_VALIDATION_FORM_EMAIL_FOCUS_UPDATE"
export interface FlowsSigninLinkValidationFormEmailUpdateFocusAction {
  type: typeof FlowsSigninLinkValidationFormEmailUpdateFocusType
  payload: { focus: boolean }
}

export type AuthSigninLinkValidationActionTypes =
  | FlowsSigninLinkValidationProcessSetErrorAction
  | FlowsSigninLinkValidationProcessFetchingAction
  | FlowsSigninLinkValidationProcessFetchEndAction
  | FlowsSigninLinkValidationProcessErrorsSetAction
  | FlowsSigninLinkValidationProcessSuccessUpdateAction
  | FlowsSigninLinkValidationStepsNextAction
  | FlowsSigninLinkValidationStepsPreviousAction
  | FlowsSigninLinkValidationFormEmailUpdateAction
  | FlowsSigninLinkValidationFormEmailCheckAction
  | FlowsSigninLinkValidationFormEmailUpdateStateAction
  | FlowsSigninLinkValidationFormEmailUpdateFocusAction
