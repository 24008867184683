import dayjs from "dayjs"
import {
  StatEntity,
  StatDisplayable,
  StatTotalDisplayable,
  StatByLevelDisplayable,
} from "../entities/StatEntity"
import { IStatsRepositoryPeriod } from "../interfaces/IStatsRepository"
import { createDailyInterval, createYearInterval } from "../utils/date"
import levels from "../constants/levels.json"

export class StatsAggregationService {
  static aggregate(params: {
    period: IStatsRepositoryPeriod
    endDate: Date
    stats: StatEntity[]
  }): StatDisplayable[] {
    if (params.period === "week") return this.aggregateByWeek(params)
    if (params.period === "month") return this.aggregateByMonth(params)
    if (params.period === "year") return this.aggregateByYear(params)

    throw new Error("period is not set")
  }

  static applyAggregation(params: {
    endDate: Date
    stats: StatEntity[]
    range: string[]
  }) {
    const reduce: StatDisplayable[] = []

    params.range.forEach((date) => {
      const defaultEntity = { duration: 0, errors: 0, date, clues: 0 }

      const reduced = params.stats.reduce((acc, value) => {
        if (value.date !== date) return acc

        return {
          duration: (acc.duration || 0) + value.duration,
          date: value.date,
          errors: (acc.errors || 0) + value.errors,
          clues: (acc.clues || 0) + value.clues,
        }
      }, defaultEntity)

      reduce.push(reduced)
    })

    return reduce.sort((a, b) => (dayjs(a.date).isBefore(b.date) ? -1 : 1))
  }

  static aggregateByYear(params: { endDate: Date; stats: StatEntity[] }) {
    const range = createYearInterval(params.endDate)

    const statsWithDateWithoutTime = params.stats.map((stat) => {
      const date = dayjs(stat.date).format("YYYY-MM")
      return { ...stat, date }
    })

    return this.applyAggregation({
      ...params,
      stats: statsWithDateWithoutTime,
      range,
    })
  }

  static aggregateByMonth(params: { endDate: Date; stats: StatEntity[] }) {
    const range = createDailyInterval(30, params.endDate)

    const statsWithDateWithoutTime = params.stats.map((stat) => {
      const date = dayjs(stat.date).format("YYYY-MM-DD")
      return { ...stat, date }
    })

    return this.applyAggregation({
      ...params,
      stats: statsWithDateWithoutTime,
      range,
    })
  }

  static aggregateByWeek(params: { endDate: Date; stats: StatEntity[] }) {
    const range = createDailyInterval(7, params.endDate)

    const statsWithDateWithoutTime = params.stats.map((stat) => {
      const date = dayjs(stat.date).format("YYYY-MM-DD")
      return { ...stat, date }
    })

    return this.applyAggregation({
      ...params,
      stats: statsWithDateWithoutTime,
      range,
    })
  }

  static aggregateTotal(params: { stats: StatEntity[] }): StatTotalDisplayable {
    const defaultEntity = { duration: 0, games: 0, errors: 0, clues: 0 }

    const reduced = params.stats.reduce((acc, value) => {
      return {
        games: (acc.games || 0) + 1,
        duration: (acc.duration || 0) + value.duration,
        errors: (acc.errors || 0) + value.errors,
        clues: (acc.clues || 0) + value.clues,
      }
    }, defaultEntity)

    return reduced
  }

  static aggregateByLevels(params: { stats: StatEntity[] }): {
    [key: string]: StatByLevelDisplayable
  } {
    const defaultEntity = {
      duration: 0,
      games: 0,
      errors: 0,
      level: "",
      clues: 0,
    }

    const levelsObject: { [key: string]: typeof defaultEntity } = levels.reduce(
      (acc, value) => {
        acc[value] = {
          ...defaultEntity,
          level: value,
        }

        return acc
      },
      {}
    )

    params.stats.forEach((stat) => {
      const level = levelsObject[stat.level]

      levelsObject[stat.level] = {
        ...level,
        duration: (level?.duration || 0) + (stat.duration || 0),
        games: (level?.games || 0) + 1,
        errors: (level?.errors || 0) + (stat.errors || 0),
        clues: (level?.clues || 0) + (stat.clues || 0),
      }
    })

    return levelsObject
  }
}
