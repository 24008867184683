import dayjs from "dayjs"
import { HEARTS_ONE_DAY_POWER_UP } from "../../database/marketplace-items"
import { MarketplaceBougthItemEntity } from "../../entities/MarketplaceEntity"
import * as types from "./types"

interface MarketplaceState {
  fetching: boolean
  items: Map<MarketplaceBougthItemEntity["itemId"], MarketplaceBougthItemEntity>
  itemFetching: string | null
}

const initialState: MarketplaceState = {
  fetching: false,
  items: new Map(),
  itemFetching: null,
}

export function marketplaceReducer(
  state = initialState,
  action: types.MarketplaceActionTypes
): MarketplaceState {
  if (action.type === types.setFetchingMarketplace) {
    return {
      ...state,
      fetching: action.payload.fetching,
    }
  }
  if (action.type === types.setFetchingItem) {
    return {
      ...state,
      itemFetching: action.payload.itemId,
    }
  }

  if (action.type === types.storeMarketplace) {
    const items = action.payload.items.filter((item) => {
      if (item.itemId === HEARTS_ONE_DAY_POWER_UP) {
        if (dayjs(item.bougthAt).add(1, "day").isAfter(dayjs())) return true
        return false
      }

      return true
    })

    return {
      ...state,
      items: new Map(items.map((item) => [item.itemId, item])),
    }
  }

  if (action.type === types.refresh) {
    const items = Array.from(state.items.values()).filter((item) => {
      if (item.itemId === HEARTS_ONE_DAY_POWER_UP) {
        if (dayjs(item.bougthAt).add(1, "day").isAfter(dayjs())) return true
        return false
      }

      return true
    })

    return {
      ...state,
      items: new Map(items.map((item) => [item.itemId, item])),
    }
  }

  if (action.type === types.buyItem) {
    state.items.set(action.payload.item.itemId, action.payload.item)

    return {
      ...state,
      items: new Map(state.items),
    }
  }

  return state
}
