import * as types from "./types"

interface CoinsState {
  fetching: boolean
  amount: number
}

const initialState: CoinsState = {
  fetching: false,
  amount: 0,
}

export function coinsReducer(
  state = initialState,
  action: types.CoinsActionTypes
): CoinsState {
  if (action.type === types.setFetchingCoins) {
    return {
      ...state,
      fetching: action.payload.fetching,
    }
  }

  if (action.type === types.addCoins) {
    return {
      ...state,
      amount: action.payload.amount + state.amount,
    }
  }

  if (action.type === types.spend) {
    return {
      ...state,
      amount: Math.max(state.amount - action.payload.amount, 0),
    }
  }

  if (action.type === types.storeCoins) {
    return {
      ...state,
      amount: action.payload.amount,
    }
  }

  return state
}
