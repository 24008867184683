import { ChestEntity } from "../../entities/ChestEntity"

export const storeChests = "storeChests"
export interface storeChestsAction {
  type: typeof storeChests
  payload: {
    chests: ChestEntity[]
  }
}

export const setFetchingChests = "setFetchingChests"
export interface setFetchingChestsAction {
  type: typeof setFetchingChests
  payload: {
    fetching: boolean
  }
}

export const openModalChest = "openModalChest"
export interface openModalChestAction {
  type: typeof openModalChest
  payload: {}
}

export type ChestsActionTypes =
  | storeChestsAction
  | setFetchingChestsAction
  | openModalChestAction
