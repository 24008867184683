import { MarketplaceBougthItemEntity } from "../../entities/MarketplaceEntity"

export const storeMarketplace = "storeMarketplace"
export interface storeMarketplaceAction {
  type: typeof storeMarketplace
  payload: {
    items: MarketplaceBougthItemEntity[]
  }
}

export const refresh = "MARKETPLACE_refresh"
export interface refreshAction {
  type: typeof refresh
}

export const buyItem = "MARKETPLACE_buyItem"
export interface buyItemAction {
  type: typeof buyItem
  payload: {
    item: MarketplaceBougthItemEntity
  }
}

export const setFetchingMarketplace = "setFetchingMarketplace"
export interface setFetchingMarketplaceAction {
  type: typeof setFetchingMarketplace
  payload: {
    fetching: boolean
  }
}

export const setFetchingItem = "MARKETPLACE__setFetchingItem"
export interface setFetchingItemAction {
  type: typeof setFetchingItem
  payload: {
    itemId: string | null
  }
}

export type MarketplaceActionTypes =
  | storeMarketplaceAction
  | setFetchingMarketplaceAction
  | setFetchingItemAction
  | refreshAction
  | buyItemAction
