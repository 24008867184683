import { IUniqueNameGeneratorService } from "../interfaces/IUniqueNameGeneratorService"
import axios from "axios"

export class ApiUniqueNameGeneratorService
  implements IUniqueNameGeneratorService
{
  async get(): Promise<{ name: string }> {
    try {
      const response = await axios.get<{ name: string }>(
        "https://zyzqy9zgq2.execute-api.eu-west-1.amazonaws.com/v0/"
      )

      return response.data || { name: Date.now().toString() }
    } catch (error) {
      return { name: Date.now().toString() }
    }
  }
}
