import { GameEntity } from "../../entities/GameEntity"
import { HistoryEntity } from "../../entities/HistoryEntity"
import { StatEntity } from "../../entities/StatEntity"
import { PlayService } from "../../services/PlayService"

export const SelectGame = "REDUX_PLAY_SELECT_GAME"
export const StoreGames = "REDUX_PLAY_STORE_GAMES"
export const StoreChallenges = "REDUX_PLAY_STORE_CHALLENGES"
export const ChangeCel = "REDUX_PLAY_CHANGE_CEL"
export const ShowClue = "REDUX_PLAY_SHOW_CLUE"
export const StoreHistory = "REDUX_PLAY_STORE_HISTORY"
export const FetchEnd = "REDUX_PLAY_FETCH_END"
export const Fetching = "REDUX_PLAY_FETCHING"
export const Reset = "REDUX_PLAY_RESET"
export const Tick = "REDUX_PLAY_TICK"
export const Pause = "REDUX_PLAY_PAUSE"
export const Resume = "REDUX_PLAY_RESUME"
export const NextChoose = "REDUX_PLAY_NEXT_CHOOSE"
export const PreviousChoose = "REDUX_PLAY_PREVIOUS_CHOOSE"
export const ChangeDraft = "REDUX_PLAY_CHANGE_DRAFT"
export const ToggleDraftMode = "REDUX_PLAY_TOGGLE_DRAFT_MODE"
export const SelectCel = "REDUX_PLAY_SELECT_CEL"
export const Undo = "REDUX_PLAY_UNDO"

export interface UndoAction {
  type: typeof Undo
}

export interface ShowClueAction {
  type: typeof ShowClue
}

export interface TickAction {
  type: typeof Tick
}

export interface PauseAction {
  type: typeof Pause
}

export interface ResumeAction {
  type: typeof Resume
}

export interface ToggleDraftModeAction {
  type: typeof ToggleDraftMode
}

export interface StoreGamesAction {
  type: typeof StoreGames
  payload: { games: GameEntity[] }
}

export interface StoreChallengesAction {
  type: typeof StoreChallenges
  payload: { challenges: GameEntity[] }
}

export interface ResetAction {
  type: typeof Reset
}

export interface NextChooseAction {
  type: typeof NextChoose
}

export interface PreviousChooseAction {
  type: typeof PreviousChoose
}

export interface StoreHistoryAction {
  type: typeof StoreHistory
  payload: { history: HistoryEntity[] }
}

export const StoreGameAndContinuePlaying =
  "REDUX_PLAY_STORE_AND_CONTINUE_PLAYING"
export interface StoreGameAndContinuePlayingAction {
  type: typeof StoreGameAndContinuePlaying
  payload: { game: PlayService }
}

export interface ChangeDraftAction {
  type: typeof ChangeDraft
  payload: { value: number | null }
}

export interface ChangeCelAction {
  type: typeof ChangeCel
  payload: { value: number | null }
}

export interface SelectGameAction {
  type: typeof SelectGame
  payload: { lifePowerUp?: boolean }
}

export interface SelectCelAction {
  type: typeof SelectCel
  payload: { id: string }
}

export interface FetchingAction {
  type: typeof Fetching
}

export interface FetchEndAction {
  type: typeof FetchEnd
}

export const Up = "REDUX_PLAY_UP"
export interface UpAction {
  type: typeof Up
}

export const ToggleErrors = "REDUX_PLAY_TOGGLE_ERRORS"
export interface ToggleErrorsAction {
  type: typeof ToggleErrors
}

export const Down = "REDUX_PLAY_Down"
export interface DownAction {
  type: typeof Down
}

export const Left = "REDUX_PLAY_Left"
export interface LeftAction {
  type: typeof Left
}

export const Right = "REDUX_PLAY_Right"
export interface RightAction {
  type: typeof Right
}

export const StoreStatsAndCreateShareableLink =
  "REDUX_PLAY_StoreStatsAndCreateShareableLink"
export interface StoreStatsAndCreateShareableLinkAction {
  type: typeof StoreStatsAndCreateShareableLink
  payload: Omit<StatEntity, "id" | "date">
}

export const RecoverOneLifeWithAds = "REDUX_PLAY_RecoverOneLifeWithAds"
export interface RecoverOneLifeWithAdsAction {
  type: typeof RecoverOneLifeWithAds
}

export type PlayActionTypes =
  | RecoverOneLifeWithAdsAction
  | StoreStatsAndCreateShareableLinkAction
  | UpAction
  | DownAction
  | LeftAction
  | RightAction
  | SelectGameAction
  | NextChooseAction
  | UndoAction
  | SelectCelAction
  | ToggleDraftModeAction
  | ChangeDraftAction
  | PreviousChooseAction
  | ResetAction
  | StoreGamesAction
  | StoreChallengesAction
  | ChangeCelAction
  | StoreHistoryAction
  | TickAction
  | ToggleErrorsAction
  | StoreGameAndContinuePlayingAction
  | PauseAction
  | ResumeAction
  | ShowClueAction
  | FetchingAction
  | FetchEndAction
