import { ThunkAction } from "redux-thunk"
import { RootState } from "../store"
import * as types from "./types"

export const storeNewsletter = (
  payload: types.storeNewsletterAction["payload"]
): types.NewsletterActionTypes => ({
  type: types.storeNewsletter,
  payload,
})

export const $fetchNewsletter =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, auth, lang } = getState()

    if (!auth.user?.id) return false

    const newsletter = await di.NewsletterRepository.get({
      userId: auth.user.id,
    })

    if (!newsletter) {
      const create = await di.NewsletterRepository.update({
        userId: auth.user.id,
        newsletter: {
          subscribed: true,
          lang: lang.lang,
        },
      })

      dispatcher(storeNewsletter({ newsletter: create }))
    } else {
      dispatcher(storeNewsletter({ newsletter }))
    }
  }
