import { navigate } from "gatsby"
import { ILocationService } from "../interfaces/ILocationService"

export class GatsbyLocationService implements ILocationService {
  navigate(to: string, state: {}) {
    navigate(to, { state })
  }

  getFullUrl() {
    return window.location.href
  }

  getPathname() {
    return window.location.pathname;
  }

  getOrigin() {
    return window.location.origin
  }
}
