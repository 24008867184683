import { PointsEntity } from "../entities/PointsEntity"
import {
  IPointsRepository,
  PointsUpdateProfileReturnType,
} from "../interfaces/IPointsRepository"

export class InMemoryPointsRepository implements IPointsRepository {
  private points: number = 0
  private leaderboard: PointsEntity[] = []

  async fetchAll(params: { limit: number }): Promise<PointsEntity[]> {
    return this.leaderboard.slice(0, params.limit)
  }

  async fetch(): Promise<{ points: number }> {
    return { points: this.points }
  }

  async getUser(params: { userId: string }): Promise<PointsEntity | null> {
    return this.leaderboard.find((board) => board.userId === params.userId)
  }

  async store(params: PointsEntity) {
    this.points = params.points
    return params
  }

  async storeLeaderboard(points: PointsEntity[]) {
    this.leaderboard = points
    return points
  }

  async sync(params: { userId: string }): Promise<{ succeed: boolean }> {
    return { succeed: true }
  }

  async updateProfile(params: {
    avatar?: string | undefined
    username?: string | undefined
  }): Promise<PointsUpdateProfileReturnType> {
    return { success: true }
  }
}
