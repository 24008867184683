import { AchievementWithRewardEntity } from "../entities/AchievementEntity"

export const getListOfAvailableAvatars = (
  achievements?: AchievementWithRewardEntity[]
): string[] => {
  const avatarsFromAchievements = (achievements || []).filter(
    ({ goal, value }) => {
      return value >= goal
    }
  )

  return avatarsFromAchievements
    .map(({ reward }) => reward)
    .concat(getListOfStaticAvailableAvatars())
}

export const getListOfStaticAvailableAvatars = () => {
  const types = ["dogs", "cats"]
  const howManyAvatars = 30

  return types.flatMap((type) =>
    Array.from({ length: howManyAvatars }).map((e, index) => {
      return `${type}/${index + 1}.png`
    })
  )
}

export const getRandomAvatar = () => {
  const avatars = getListOfAvailableAvatars()

  const index = Math.round(Math.random() * (avatars.length - 1))

  return avatars[index]
}
