import React from "react"

export const Ul = ({ children, id }) => (
  <div className="px-4 md:max-w-3xl container mx-auto">
    <ul
      className="mb-4 text-base list-disc md:text-lg list-inside pl-4 text-slate-900"
      id={id}
    >
      {children}
    </ul>
  </div>
)

export const Li = ({ children, id }) => (
  <li className="my-2" id={id}>
    {children}
  </li>
)
