import { GameEntity } from "../entities/GameEntity"
import { IPlayRepository } from "../interfaces/IPlayRepository"
import { PlayService } from "../services/PlayService"

export class LocalStoragePlayRepository implements IPlayRepository {
  async load(id: string): Promise<PlayService | null> {
    try {
      const game = window.localStorage.getItem(id)
      if (!game) return null

      return JSON.parse(game)
    } catch (e) {
      return null
    }
  }

  async save(id: string, game: PlayService): Promise<PlayService | null> {
    try {
      window.localStorage.setItem(id, JSON.stringify(game))
      return game
    } catch (e) {
      return game
    }
  }

  async remove(id: string): Promise<boolean> {
    try {
      window.localStorage.removeItem(id)

      return true
    } catch (e) {
      return true
    }
  }
}
