import { ILocalStorageRepository } from "../interfaces/ILocalStorageRepository"

export class InMemoryLocalStorageRepository implements ILocalStorageRepository {
  private storage = new Map()

  async store(name: string, value: { [x: string]: any }) {
    this.storage.set(name, value)
  }

  async get<T>(name: string) {
    return (this.storage.get(name) as T) || null
  }
}
