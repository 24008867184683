import {
  LevelAndPointsService,
  PointsStepEntity,
} from "../../services/LevelAndPointsService"
import * as types from "./types"
import {
  LeaderboardEntity,
  MetaLeaderboardEntity,
} from "../../entities/LeaderboardEntity"

interface StatsState {
  fetching: boolean
  points: number
  level: number
  leaderboard: LeaderboardEntity[]
  leaderboardPoints: number
  fetchingLeaderboard: boolean
  beforeLevelPoints: number
  nextLevelPoints: number
  steps: Array<PointsStepEntity>
  meta: MetaLeaderboardEntity | null
  promotionModal: {
    actualLeague: number
    lastLeague: number
    type: "promotion" | "relegation" | "egation"
  }
  before: {
    points: number
    level: number
  }
}

const initialState: StatsState = {
  fetching: false,
  fetchingLeaderboard: false,
  leaderboardPoints: 0,
  points: 0,
  level: 1,
  beforeLevelPoints: 0,
  meta: null,
  leaderboard: [],
  steps: [],
  nextLevelPoints: LevelAndPointsService.getNextLevel(1),
  promotionModal: {
    actualLeague: 0,
    lastLeague: 0,
    type: "egation",
  },
  before: {
    points: 0,
    level: 1,
  },
}

export function pointsReducer(
  state = initialState,
  action: types.StatsActionTypes
): StatsState {
  if (action.type === types.Fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === types.FetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  if (action.type === types.Store) {
    return {
      ...state,
      ...action.payload,
      beforeLevelPoints: LevelAndPointsService.getNextLevel(
        action.payload.level - 1
      ),
      before: {
        points: state.points,
        level: state.level,
      },
    }
  }

  if (action.type === types.leaderboardOpenPromotionModal) {
    return {
      ...state,
      promotionModal: {
        ...action.payload,
      },
    }
  }

  if (action.type === types.SetFetchingLeaderboard) {
    return {
      ...state,
      fetchingLeaderboard: action.payload.fetching,
    }
  }

  if (action.type === types.StoreLeaderboard) {
    return {
      ...state,
      leaderboard: action.payload.leaderboard.sort(
        (a, b) => b.points - a.points
      ),
      leaderboardPoints: action.payload.userPoints,
      meta: action.payload.meta,
    }
  }

  return state
}
