import * as types from "./types"

export const selectGame = (): types.PrintActionTypes => ({
  type: types.SelectGame,
})

export const storeGames = (
  payload: types.StoreGamesAction["payload"]
): types.PrintActionTypes => ({
  type: types.StoreGames,
  payload,
})
