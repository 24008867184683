export const storeAds = "storeAds"
export interface storeAdsAction {
  type: typeof storeAds
  payload: {
    showAds: boolean
  }
}

export const storeIsPremium = "storeIsPremium"
export interface storeIsPremiumAction {
  type: typeof storeIsPremium
  payload: {
    isPremium: boolean
  }
}

export type GlobalActionTypes = storeAdsAction | storeIsPremiumAction
