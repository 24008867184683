import {
  NotificationCoinEntity,
  NotificationEntity,
} from "../../entities/NotificationEntity"

export const store = "REDUX_NOTIFICATIONS_STORE"
export interface storeAction {
  type: typeof store
  payload: NotificationEntity
}

export const remove = "REDUX_NOTIFICATIONS_REMOVE"
export interface removeAction {
  type: typeof remove
  payload: { id: NotificationEntity["id"] }
}

export const storeCoins = "NOTIFICATIONS_storeCoins"
export interface storeCoinsAction {
  type: typeof storeCoins
  payload: NotificationCoinEntity
}

export const removeCoin = "NOTIFICATIONS_removeCoin"
export interface removeCoinAction {
  type: typeof removeCoin
  payload: { id: NotificationCoinEntity["id"] }
}

export type NotificationsActionTypes =
  | storeAction
  | removeAction
  | removeCoinAction
  | storeCoinsAction
