import { MarketplaceBougthItemEntity } from "../entities/MarketplaceEntity"
import { IMarketplaceRepository } from "../interfaces/IMarketplaceRepository"

export class InMemoryMarketplaceRepository implements IMarketplaceRepository {
  private items: Map<
    MarketplaceBougthItemEntity["id"],
    MarketplaceBougthItemEntity
  > = new Map()

  async getAll(params: {
    userId: string
  }): Promise<MarketplaceBougthItemEntity[]> {
    return Array.from(this.items.values())
  }

  async getOne(params: {
    userId: string
    itemId: string
  }): Promise<MarketplaceBougthItemEntity> {
    return Array.from(this.items.values())[0] || null
  }

  async store(params: {
    userId: string
    item: MarketplaceBougthItemEntity
  }): Promise<MarketplaceBougthItemEntity> {
    this.items.set(params.item.id, params.item)
    return params.item
  }
}
